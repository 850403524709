import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

import './style.css'


export default function ProjectItemView(props) {
    const {
        name = '',
        timestamp,
        thumbnail, hash,
        onGo = () => {

        }, width, height,
        onEdit = () => {

        },
        onRename = () => {

        },
        onDuplicate = () => {

        },
        onDelete = () => {

        },
        onShare = () => {

        },
        onDownload = () => {

        },
        onView = () => {

        },
        originalUrl,
        binaryProof
    } = props;
    // const [dropdownVisible, setDropdownVisible] = useState(true);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const node = useRef();
    const handleClick = e => {
        if (node == undefined || node.current == undefined) {
            return;
        }
        if (node.current.contains(e.target)) {
            return;
        }
        setDropdownVisible(false);
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const imUrl = CommonHelper.getIconByUrl(originalUrl);

    return (
        <IraItem>
            <IraItemLeft onClick={() => {
                onGo();
            }}>
                <ProjTypePlaceholder>
                    <IraFileTypeImg src={require('../../../assets/images/word_doc_img.svg')}/>
                </ProjTypePlaceholder>
                <IraItemTextInfo>
                    <IraItemName>
                        {name}
                    </IraItemName>
                    <IraItemDate>
                        {moment(timestamp).format('DD.MM.YYYY')}
                    </IraItemDate>
                </IraItemTextInfo>
            </IraItemLeft>
            <IraItemRight ref={node}>
                <IraTripletImg
                    src={require('../../../assets/images/triplet_dots.svg')}
                    onClick={() => {
                        setDropdownVisible(!dropdownVisible);
                    }}
                />
                {dropdownVisible == false ? null :
                    <DropdownPlaceholder>
                        <DropdownItem onClick={() => {
                            onView();
                        }}>
                            Просмотр
                        </DropdownItem>
                        {/*<DropdownItem onClick={() => {*/}
                        {/*    onDownload();*/}
                        {/*}}>*/}
                        {/*    Скачать*/}
                        {/*</DropdownItem>*/}
                        <DropdownItem onClick={() => {
                            onRename();
                        }}>
                            Переименовать
                        </DropdownItem>
                        <DropdownItem onClick={() => {
                            onDelete();
                        }}>
                            <DeleteSpan>
                                Удалить
                            </DeleteSpan>
                        </DropdownItem>
                    </DropdownPlaceholder>
                }
            </IraItemRight>
        </IraItem>

    );
}


const IraFileTypeImg = styled.img`
    height: 60px;
`;

const ProjTypePlaceholder = styled.div`
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const IraItemTextInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const IraItemName = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #222222;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const IraItemDate = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #222222;
    opacity: 0.5;
    margin-top: 5px;
`;

const IraItem = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const IraItemLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const IraItemRight = styled.div`
    width: 30px;
    position: relative;
`;

const IraTripletImg = styled.img`
    height: 4px;
    cursor: pointer;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
`;

const Logo = styled.img`
    height: 100px;
`;

const VoidPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const ThumbPlaceholder = styled.div`
    flex: 1;
    width: 100%;
`;

const ThumbImage = styled.div`
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.src});
    cursor: pointer;
    :hover{
      opacity: 0.95;
    }
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 8px;
`;

const MetaPlaceholder = styled.div`
    flex: 1;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
`;

const Name = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
`;

const Control = styled.div`
    width: 16px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;


const DotsImg = styled.img`
    width: 5px;
    height: 16px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const SizeInfoPlaceholder = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #7B7F9E;
`;

const DropdownPlaceholder = styled.div`
    position: absolute;
    top: 23px;
    z-index: 1000;
    right: 0px;
    width: 180px;
    background: #3F435A;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 15px;
`;

const DropdownItem = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 36px;
    color: #FFFFFF;
    text-align: right;
    cursor: pointer;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    
    :hover{
      opacity: 0.8;
    }
`;

const DeleteSpan = styled.span`
    color: #EA523D;
`;
