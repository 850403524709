import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Spin from 'arui-feather/spin'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

import {Button} from "../../ui/Button";
import CommonHelper from "../../../helpers/CommonHelper";
import FaqAPI from "../../../api/FaqAPI";

import Tabs, {TabItem} from "../../ui/Tabs";
import ViewFaqItemsTool from "../tools/ViewFaqItemsTool";

export default function UpdateFaqPanel(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(() => {
        FaqAPI.getFaqItems().then(items => {
            setItems(items);
            setLoading(false);
        })
    }, []);

    return (
        <Wrapper>

            <Spin visible={loading}/>

            <Tabs tabs={[
                {
                    label: 'Редактирование',
                    content: (
                        <TabItem>
                            <ItemsList>
                                {items.map((item, k) => {
                                    let {id, question, answer, type, isLanding, isApp} = item;
                                    return (
                                        <Item key={`${item.id}_${k}`}>
                                            <QuestionPlaceholder>
                                                <Input value={question} placeholder={'Вопрос'} onChange={evt => {
                                                    let newQuestion = evt.target.value;
                                                    setItems(items.map(item => (item.id == id ? ({
                                                        ...item,
                                                        question: newQuestion
                                                    }) : item)))
                                                }}/>
                                            </QuestionPlaceholder>
                                            <AnswerPlaceholder>
                                <Textarea value={answer} placeholder={'Ответ'} onChange={evt => {
                                    let newAnswer = evt.target.value;
                                    setItems(items.map(item => (item.id == id ? ({...item, answer: newAnswer}) : item)))
                                }}/>
                                            </AnswerPlaceholder>

                                            <ItemControlsPlaceholder>
                                                <ItemControlButton style={{color: 'red'}} onClick={() => {
                                                    if (window.confirm('Вы уверены?') == false) {
                                                        return;
                                                    }
                                                    setItems(items.filter(it => (it.id != id)));
                                                }}>
                                                    Удалить
                                                </ItemControlButton>
                                                {k == 0 ? null :
                                                    <ItemControlButton onClick={() => {
                                                        setItems(
                                                            swap(items, +k - 1, +k)
                                                        )
                                                    }}>
                                                        Вверх
                                                    </ItemControlButton>
                                                }
                                                {k == items.length - 1 ? null :
                                                    <ItemControlButton onClick={() => {
                                                        setItems(
                                                            swap(items, +k + 1, +k)
                                                        )
                                                    }}>
                                                        Вниз
                                                    </ItemControlButton>
                                                }
                                            </ItemControlsPlaceholder>
                                        </Item>
                                    )
                                })}
                            </ItemsList>

                            <BottomPlaceholder>

                                <div style={{marginBottom: 15}}>
                    <span style={{cursor: 'pointer'}} onClick={() => {
                        setItems(items.concat([{id: uuid(), question: '', answer: '', isApp: true, isLanding: true}]));
                    }}>
                        Добавить еще
                    </span>

                                </div>

                                <Button onClick={() => {
                                    setLoading(true);
                                    FaqAPI.updateFaqItems(items).then(() => {
                                        setLoading(false);
                                    });
                                }}>
                                    <Spin visible={loading}/>
                                    {loading == true ? null :
                                        <span>
                            Сохранить
                        </span>
                                    }
                                </Button>

                            </BottomPlaceholder>
                        </TabItem>
                    )
                },
                {
                    label: 'Просмотр',
                    content: (
                        <TabItem>
                            <ViewFaqItemsTool items={items} />
                        </TabItem>
                    )
                }
            ]}/>


        </Wrapper>
    );
}

const swap = (input, index_A, index_B) => {
    const cpInput = JSON.parse(JSON.stringify(input));
    let temp = cpInput[index_A];
    cpInput[index_A] = input[index_B];
    cpInput[index_B] = temp;
    return cpInput;
}

const Wrapper = styled.div`
    
`;

const ItemsList = styled.div`
    
`;

const Item = styled.div`
    margin-bottom: 40px;
    padding: 10px;
    background: whitesmoke;
`;

const QuestionPlaceholder = styled.div`
    
`;

const AnswerPlaceholder = styled.div`
    
`;

const TypePlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const BottomPlaceholder = styled.div`
    
`;

const ItemControlsPlaceholder = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ItemControlButton = styled.div`
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const Check = styled.div`
    width: 24px;
    height: 24px;
    border: 2px solid blue;
    border-radius: 4px;
    background-color: ${props => (props.checked == true ? 'blue' : 'white')};
    cursor: pointer;
    display: inline-block;
    margin-left: 4px;
    :hover{
      opacity: 0.5;
    }
`;
