import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UpdateProfileForm from "../forms/UpdateProfileForm";

import * as actions from '../../../redux/actions/UsersActions'
import CommonHelper from "../../../helpers/CommonHelper";

export default function UserProfilePanel(props) {
    const {loading, currentUserId, currentUser} = useMappedState(useCallback(state => {
        return {
            loading: state.users.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, []));
    const dispatch = useDispatch();

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopName>
                    Мои профиль
                </TopName>
                <TopRight>
                    {/*<span style={{color: '#EF3826', cursor: 'pointer', fontWeight: 'bold'}} onClick={async () => {*/}
                        {/*await dispatch(actions.logOut());*/}
                        {/*CommonHelper.linkTo('/');*/}
                    {/*}}>*/}
                        {/*Выход*/}
                    {/*</span>*/}
                </TopRight>
            </TopPlaceholder>

            <UpdateProfileForm loading={loading} {...currentUser}
                               onSave={async d => {
                                   await dispatch(actions.updateUser({...d, id: currentUserId}))
                               }}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1200px){
      width: 100%;
    }
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const TopName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: #465E6F;
`;

const TopRight = styled.div`
    
`;
