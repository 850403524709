import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function UpdateProjectForm(props) {
    const {
        loading = false,
        onSave = d => {

        },
        buttonName = 'Save'
    } = props;
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [url, setUrl] = useState(props.url == undefined ? '' : props.url);

    const canSubmit = (isURL(url) && url.indexOf('http') == 0);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Website URL
                </Label>
                <Input placeholder={'https://yandex.ru'}
                       autoFocus={true} value={url} onChange={evt => {
                    setUrl(evt.target.value.replace(/ /g, ''));
                }}/>
            </Field>

            <Field>
                <Label>
                    Website name
                </Label>
                <Input autoFocus={false}
                       placeholder={'Cool website'}
                       value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <BottomField>
                <Button disabled={!canSubmit} onClick={() => {
                    if (canSubmit == false || loading == true) {
                        return;
                    }
                    onSave({name, url})
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <span>{buttonName}</span>
                    }
                </Button>
            </BottomField>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
`;

const Input = styled.input`
    background: #212330;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    height: 50px;
    width: 100%;
    padding-left: 15px;
    
    font-weight: bold;
    
    font-size: 17px;
    line-height: 23px;
    
    color: #FFFFFF;
    
    :focus{
      border: 2px solid rgba(255, 255, 255, 0.03);
    }
`;

const BottomField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
`;

const Button = styled.div`
    background: #24B6BC;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 46px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
  
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled == true ? 0.6 : 1)};
    
    :hover{
      opacity: 0.9;
    }
`;

function isURL(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}
