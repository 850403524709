import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UniTemplate from "../templates/UniTemplate";
import VerificationTool from "../verification/tools/VerificationTool";

export default function VerificationApp(props) {
    let {id} = props.match.params;

    return (
        <UniTemplate active={`verification`}>

            <Wrapper>

                <VerificationTool/>

            </Wrapper>


        </UniTemplate>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 40px;
    color: black;
    @media(max-width: 1200px){
      width: 100%;
    }
`;
