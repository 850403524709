import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import UpdateRidForm from "../forms/UpdateRidForm";

import * as actions from '../../../redux/actions/RidsActions'

export default function AllRidsPanel(props) {
    const [addVisible, setAddVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    const {loading, isAdmin, currentUserId, rids = []} = useMappedState(useCallback(state => {
        let rids = state.rids.ridsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp));
        return {
            loading: state.users.loading || state.rids.loading,
            rids: rids,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, []));
    const dispatch = useDispatch();
    let selectedItem = (selectedId == undefined) ? undefined : rids.filter(a => (a.id == selectedId))[0];

    useEffect(() => {
        dispatch(actions.loadAllRids());
    }, []);

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button style={{margin: 0}} onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить тип РИДа
                </Button>
            </TopPlaceholder>

            <ListPlaceholder>
                {rids.map((a, i) => {
                    let isSelected = (a.id == selectedId);
                    let parentRid = (a.parentId == undefined) ? undefined : rids.filter(b => (b.id == a.parentId))[0]
                    return (
                        <RidItem key={`${i}_${a.id}`} selected={isSelected} onClick={() => {
                            setSelectedId(a.id);
                        }}>

                            <RidName>
                                {`${parentRid == undefined ? '' : `${parentRid.name} > `}${a.name}`}
                            </RidName>

                            {a.description == undefined ? null :
                                <RidDescription>
                                    {a.description}
                                </RidDescription>
                            }

                            <FieldsDescription>
                                {a.fields.map(x => x.name).join(', ')}
                            </FieldsDescription>

                        </RidItem>
                    )
                })}
            </ListPlaceholder>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <UpdateRidForm
                            allRids={rids}
                            loading={loading}
                            onSave={async d => {
                                await dispatch(actions.createRid(d));
                                setAddVisible(false);
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={(selectedItem != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedItem == undefined ? null :
                    <div>
                        <UpdateRidForm {...selectedItem}
                                       allRids={rids}
                                       loading={loading}
                                       canDelete={true}
                                       onSave={async d => {
                                           await dispatch(actions.updateRid({id: selectedItem.id, ...d}))
                                           setSelectedId(undefined);
                                       }}
                                       onDelete={async () => {
                                           await dispatch(actions.deleteRid(selectedItem.id));
                                           setSelectedId(undefined);
                                       }}
                        />
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const RidName = styled.div`
    font-size: 20px;
    color: black;
    font-weight: bold;
`;


const RidDescription = styled.div`
    font-size: 16px;
    color: black;
`;


const FieldsDescription = styled.div`
    font-size: 12px; 
    font-style: italic;
`;

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const ListPlaceholder = styled.div`
    
`;

const RidItem = styled.div`
    padding: 7px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
