import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import ProjectCertPanel from "../projects/panels/ProjectCertPanel";

export default function ProjectCertApp(props) {
    let {id} = props.match.params;

    return (
        <Wrapper active={`cert/${id}`}>

            <ProjectCertPanel id={id} />

        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    background: white;
    width: 100vw;
    box-sizing: border-box;
`;
