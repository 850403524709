import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const ITEMS = [
    {
        label: 'Депонирование РИД',
        icon: require('../../../assets/images/fa_bookmark.svg'),
        selectedIcon: require('../../../assets/images/fa_bookmark_selected.svg'),
        mainImage: require('../../../assets/images/kf_01.png')
    },
    {
        label: 'Верификация эталона файла',
        icon: require('../../../assets/images/fa_contact.svg'),
        selectedIcon: require('../../../assets/images/fa_contact_selected.svg'),
        mainImage: require('../../../assets/images/kf_03.png')
    },
    {
        label: 'Проверка свидетельства по номеру',
        icon: require('../../../assets/images/fa_envelope.svg'),
        selectedIcon: require('../../../assets/images/fa_envelope_selected.svg'),
        mainImage: require('../../../assets/images/kf_02.png')
    },
];


export default function FeaturesSection(props) {
    const [selectedNumber, setSelectedNumber] = useState(0);

    const selectedItem = ITEMS[selectedNumber];

    return (
        <Wrapper>

            <Left>
                <LeftImg src={selectedItem.mainImage}/>
            </Left>

            <Right>

                <Heading>
                    Ключевые функции
                </Heading>

                <SubP>
                    {selectedNumber != 0 ? null :
                        <>
                        Выберите тип объекта депонирования, заполните дополнительные поля и загрузите файл в требуемом формате. Для получения свидетельства о депонировании введите Ваши личные данные и все необходимые сведения о правообладателе и соавторах.
                        </>
                    }
                    {selectedNumber != 1 ? null :
                        <>
                        Загрузите оригинал файла для проверки его на эталон. После проверки актива будет выдано заключение о его соответствии оригиналу и Свидетельство о верификации.
                        </>
                    }
                    {selectedNumber != 2 ? null :
                        <>
                        Введите номер свидетельства о депонировании или хэш-файла для проверки подлинности свидетельства и просмотра полной информации о нем.
                        </>
                    }

                </SubP>

                {ITEMS.map((it, i) => {
                    let isSelecetd = (i == selectedNumber);
                    return (
                        <RightRow key={i} color={(isSelecetd == true) ? '#BB6BD9' : '#18214D'} onClick={() => {
                            setSelectedNumber(i);
                        }}>
                            <LeftCircle>
                                <CircleImage src={(isSelecetd == true) ? it.selectedIcon : it.icon}/>
                            </LeftCircle>
                            <RowLabel>
                                {it.label}
                            </RowLabel>
                        </RightRow>
                    )
                })}
            </Right>

        </Wrapper>
    );
}

const RightRow = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.color};
    cursor: pointer;
    :hover{
      color: #BB6BD9;
    }
`;

const CircleImage = styled.img`
    width: 18px;
`;

const Heading = styled.div`
    color: #465E6F;
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 20px;
    font-weight: bold;
    @media(max-width: 720px){
      font-size: 36px;
    }
`;

const SubP = styled.div`
    color: #0A1B32;
    opacity: 0.5;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 40px;
`;

const RowLabel = styled.div`
    font-size: 20px;
    font-weight: bold;
`;

const LeftCircle = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-right: 20px;
    background: #EEEEEE;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;  
    
    padding-top: 60px;
    padding-bottom: 60px;
    
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    
    @media(max-width: 720px){
      display: block;
    }
    
`;

const LeftImg = styled.img`
    width: 100%;
    border-radius: 6px;
`;

const Left = styled.div`
    width: 620px;
    margin-right: 40px;
    @media(max-width: 720px){
      width: 100%;
      margin-right: 0px;
      box-sizing: border-box;
    }
`;

const Right = styled.div`
    flex: 1;
`;
