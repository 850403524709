import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/PlansActions";
import PricingTool from "../tools/PricingTool";

export default function UserSubscriptionsPanel(props) {
    const {loading, plans, isAdmin, currentUser, currentPlanId, currentPlan} = useMappedState(useCallback(state => {
        return {
            loading: state.users.loading || state.plans.loading,
            plans: state.plans.plansMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentPlanId: state.plans.currentPlanId,
            currentPlan: state.plans.plansMap.get(state.plans.currentPlanId),
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllPlans());
    }, []);
    if (plans.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }
    if (currentUser == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <PricingTool
                currentUserId={currentUser.id}
                email={currentUser.email}
                currentPlanId={currentPlanId}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
