import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'

export default function StyledLoginForm(props) {
    const {
        onSubmit = () => {

        },
        onSignUpClick = () => {
        },
        onLostPasswordClick = () => {
        }, loading = false
    } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
        <Wrapper>

            <TopPlaceholder>
                Вход в кабинет
            </TopPlaceholder>

            <SubTopPlaceholder>
                Пожалуйста, введите свою почту и пароль, чтобы продолжить
            </SubTopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <Label>
                        E-mail
                    </Label>
                    <Input
                        placeholder={'esteban_schiller@gmail.com'}
                        autoComplete={false}
                        autoFill={false}
                        value={email} type={'email'} placeholder={''} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                </Field>
                <Field>
                    <Label style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <span>
                            Пароль
                        </span>
                        <ForgotPlaceholder>
                            <ForgotSpan style={{fontWeight: 'normal'}} onClick={() => {
                                onLostPasswordClick();
                            }}>
                                Забыли пароль?
                            </ForgotSpan>
                        </ForgotPlaceholder>
                    </Label>
                    <Input value={password}  type={'password'} placeholder={'*****'}
                           onKeyPress={e => {
                               if (e.key === 'Enter') {
                                   onSubmit({email: email.toLowerCase(), password})
                               }
                           }}
                           onChange={e => {
                               setPassword(e.target.value);
                           }}
                           autoComplete={false}
                           autoFill={false}
                    />
                </Field>


                <Field>
                    <Button
                        onClick={() => {
                            onSubmit({email: email.toLowerCase(), password})
                        }}>
                        <Spin theme={'alfa-on-color'} visible={loading}/>
                        {loading == true ? null :
                            'Войти'
                        }
                    </Button>
                </Field>
            </ContentPlaceholder>

            <BottomPlaceholder>
                <span style={{opacity: 0.65}}>
                    Нет учетной записи?
                </span>
                <SignupSpan style={{marginLeft: 16}} onClick={() => {
                    onSignUpClick();
                }}>
                    Регистрация
                </SignupSpan>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const PrivacyPlaceholder = styled.div`
    
`;

const SignupSpan = styled.span`
    cursor: pointer;
    color: #0072BC;
    letter-spacing: -0.0642857px;
    text-decoration-line: underline;
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
    margin-left: 16px;
    :hover{
      opacity: 0.7;
    }
`;

const ForgotPlaceholder = styled.div`
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    letter-spacing: -0.0642857px;
    color: #202224;
    mix-blend-mode: normal;
    opacity: 0.6;
`;

const ForgotSpan = styled.span`
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const BottomPlaceholder = styled.div`
    color: #202224;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
`;

const Wrapper = styled.div`
    
`;

const SubTopPlaceholder = styled.div`
    color: #202224;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.0642857px;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 25px;
`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: #465E6F;
    text-align: center;
`;

const ContentPlaceholder = styled.div`
    
`;


const Field = styled.div`
    margin-bottom: 25px;
    width: 100%;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 5px;
    opacity: 0.8;
    letter-spacing: -0.0642857px;
    
    color: #202224;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;
