import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import * as actions from "../../../redux/actions/ProjectsActions";
import ReactHelper from "../../../helpers/ReactHelper";

export default function UpdateProjectsPreviewDaemon(props) {
    const {loading, projects, isAdmin, currentUserId, hasAnyWithNoProof} = useMappedState(useCallback(state => {
        let projects = state.projects.projectsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp));
        // let hasAnyWithNoThumbnail = projects.reduce((f, p) => (f || (p.thumbnail == undefined)), false);
        let hasAnyWithNoProof = projects.reduce((f, p) => (f || (p.binaryProof == undefined)), false);
        return {
            loading: state.users.loading || state.projects.loading,
            projects: projects,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            hasAnyWithNoProof: hasAnyWithNoProof
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadUserProjects(currentUserId));
    }, [currentUserId]);

    ReactHelper.useInterval(() => {
        if (hasAnyWithNoProof == true) {
            dispatch(actions.loadUserProjects(currentUserId));
        }
    }, 5000);

    return (
        <Wrapper>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: none;
`;
