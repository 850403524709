import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const FaqAPI = {

    getFaqItems() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/dictionary/faq`).then(d => d.data.result.value).then(pld => {
                resolve((pld == undefined || pld.items == undefined) ? [] : pld.items);
            }).catch(err => {
                reject(err);
            });
        });
    },

    updateFaqItems(items) {
        console.log('updateFaqItems: items = ', items);
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/dictionary/faq`, {value: {items: items}}).then(d => d.data).then(pld => {
                resolve(pld);
            }).catch(err => {
                reject(err);
            });
        });
    },

}

export default FaqAPI;
