import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TopSection from "../landing/sections/TopSection";
import FeaturesSection from "../landing/sections/FeaturesSection";
import HowToSection from "../landing/sections/HowToSection";
import AboutSection from "../landing/sections/AboutSection";
import ObjectsSections from "../landing/sections/ObjectsSections";
import PlansSection from "../landing/sections/PlansSection";

const footerImg = require('../../assets/images/fo_fo.png');

export default function LandingApp(props) {


    return (
        <Wrapper>

            <WrapperInner>

                <TopSection/>

                <AboutSection/>

                <HowToSection/>

                <FeaturesSection/>

                <ObjectsSections/>

                <PlansSection/>

                <FooterPlaceholder>
                    <FooterImg src={require('../../assets/images/patent_tsu_white_logo.svg')}/>
                    <ProtectedPlaceholder style={{color: 'white', opacity: 0.8, marginTop: 30, textAlign: 'right'}}>
                        © Все права защищены.
                        <br/>
                        НИ ТГУ
                    </ProtectedPlaceholder>
                </FooterPlaceholder>

            </WrapperInner>


        </Wrapper>
    );
}

const ProtectedPlaceholder = styled.div`
    color: white;
    opacity: 0.8;
    margin-top: 30px;
    text-align: right;
    @media(max-width: 720px){
      display: none;
    }
`;

const Wrapper = styled.div`
    
`;

const WrapperInner = styled.div`
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    width: 1200px;
    background: white;
    @media(max-width: 1200px){
      width: 100%;
    }
`;

const FooterImg = styled.img`
    height: 45px;
    width: 254px;
    margin-top: 30px;
`;

const FooterPlaceholder = styled.div`
    margin-top: 40px;
    
    height: 140px;
    
    box-sizing: border-box;
    background-image: url(${footerImg});
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    
    
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    @media(max-width: 720px){
      display: block;
      padding-bottom: 20px;
    }
    
`;
