import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'

export default function UpdateUserInfoForm(props) {
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);

    const {
        onSave = () => {
        },
        loading = false,
        extraContent = undefined
    } = props;

    return (
        <Wrapper>

            <Field>
                <Label>
                    Имя
                </Label>
                <Input value={firstName} onChange={evt => setFirstName(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Фамилия
                </Label>
                <Input value={lastName} onChange={evt => setLastName(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Телефон
                </Label>
                <Input value={phone} onChange={evt => setPhone(evt.target.value)}/>
            </Field>


            <Field>
                <Button style={{marginLeft: 0}} onClick={() => {
                    onSave({firstName, lastName, phone});
                }}>
                    <Spin theme={'alfa-on-color'} visible={loading}/>
                    {loading == true ? null :
                        <span>
                             Сохранить
                        </span>
                    }
                </Button>

                {extraContent}

            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    color: white;
`;

