import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import * as actions from '../../../redux/actions/ProjectsActions'
import * as usersActions from '../../../redux/actions/UsersActions'
import CommonHelper from "../../../helpers/CommonHelper";

import moment from 'moment'

import QRCode from 'react-qr-code';

const loadingGif = require('../../../assets/images/loading_gif.gif');

const ie = x => (x == undefined || x == '');

export default function ProjectCertPanel(props) {
    const {id} = props;

    const {loading, project, user} = useMappedState(useCallback(state => {
        let project = state.projects.projectsMap.get(id);
        let user = (project == undefined) ? undefined : state.users.usersMap.get(project.userId);
        return {
            project: state.projects.projectsMap.get(id),
            loading: state.users.loading || state.projects.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            projectsLoading: state.projects.loading,
            user: user
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getProject(id));
    }, [id]);

    useEffect(() => {
        if (project != undefined) {
            console.log('!!!! loading user!!!');
            dispatch(usersActions.loadUserById(project.userId));
        }
    }, [id, (project == undefined ? '' : project.id)]);


    if (project == undefined || user == undefined) {
        return (
            <Code/>
        )
    }

    console.log('--->> user = ', user);

    let {hash, btcTxId, timestamp, userId, name, binaryProof, bgChainTxId} = project;
    let userName = (user == undefined) ? '' : `${user.lastName} ${user.firstName} ${user.middleName == undefined ? '' : user.middleName}`;
    let transactionUrl = `https://node1.patent.tsu.ru/api/v1/transactions/${bgChainTxId}`;
    let {inn, livingPlace, birthDateString} = user;

    return (
        <Wrapper>

            <Page background={require('../../../assets/images/cert/svid_1.jpg')}>

                <AbsDiv left={0} right={0} top={510} style={{textAlign: 'center', fontSize: 24, fontWeight: 'normal'}}>
                    {`№${timestamp} от ${moment(timestamp).format('DD.MM.YYYY')}`}
                </AbsDiv>

                <AbsDiv color={'#124067'} left={0} right={0} top={650} style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: 32,
                    textTransform: 'uppercase'
                }}>
                    {`${name}`}
                </AbsDiv>

                <AbsDiv left={380} top={774} style={{fontSize: 28, lineHeight: `28px`}}>
                    {userName}
                </AbsDiv>

                <AbsDiv left={380} top={830} style={{fontSize: 28, lineHeight: `28px`}}>
                    {userName}
                </AbsDiv>

                <AbsDiv left={380} top={885} style={{fontSize: 28, lineHeight: `28px`}}>
                    {moment(timestamp).format('DD.MM.YYYY HH:mm')}
                </AbsDiv>

                <AbsDiv left={380} top={941} style={{fontSize: 16, lineHeight: `28px`}}>
                    {hash}
                </AbsDiv>

                <AbsDiv left={380} top={1021}
                        style={{fontSize: 16, lineHeight: `18px`, wordBreak: 'break-all', width: 520}}>
                    {transactionUrl}
                </AbsDiv>

                <AbsDiv left={150} top={1075}
                        style={{fontSize: 16, lineHeight: `18px`, wordBreak: 'break-all', width: 520}}>
                    <QRCode value={`https://patent.tsu.ru/#/cert/${project.id}`} size={160}/>
                </AbsDiv>


            </Page>

            <Page background={require('../../../assets/images/cert/svid_2.jpg')}>

                <AbsDiv left={390} top={247} style={{fontSize: 28, lineHeight: `28px`}}>
                    {userName}
                </AbsDiv>

                {ie(birthDateString) ? null :
                    <AbsDiv left={390} top={474} style={{fontSize: 28, lineHeight: `28px`}}>
                        {birthDateString}
                    </AbsDiv>
                }

                {ie(livingPlace) ? null :
                    <AbsDiv left={390} top={529} style={{
                        fontSize: 16, lineHeight: `18px`,
                        wordBreak: 'break-all', width: 520,
                        height: 32,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'

                    }}>
                        {livingPlace}
                    </AbsDiv>
                }

                {ie(inn) ? null :
                    <AbsDiv left={390} top={591} style={{fontSize: 28, lineHeight: `28px`}}>
                        {inn}
                    </AbsDiv>
                }


                {binaryProof == undefined ? null :
                    <AbsDiv left={100} right={80} top={700}
                            style={{fontSize: 14, lineHeight: `18px`, wordBreak: 'break-all'}}>
                        <div style={{
                            fontSize: 24,
                            textTransform: 'uppercase',
                            color: '#015772',
                            marginBottom: 10
                        }}>
                            Сжатое ChainPoint-доказательство
                        </div>
                        <div>
                            {binaryProof}
                        </div>
                    </AbsDiv>
                }


            </Page>

        </Wrapper>
    );
}

const Page = styled.div`
    width: 1000px;
    height: 1414px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.background});
`;

const AbsDiv = styled.div`
    position: absolute;
    ${props => (props.left == undefined ? '' : `left: ${props.left}px;`)}
    ${props => (props.right == undefined ? '' : `right: ${props.right}px;`)}
    ${props => (props.top == undefined ? '' : `top: ${props.top}px;`)}
    ${props => (props.bottom == undefined ? '' : `top: ${props.bottom}px;`)}
    ${props => (props.color == undefined ? '' : `color: ${props.color};`)}
`;

const TopDialogImagePlaceholder = styled.div`
    height: 200px;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});
    background-color: rgba(211,211,211,0.05);
    margin-bottom: 10px;
    border-radius: 4px;
`;

const MoLink = styled.a`
    //color: white;
    color: black;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
`;

const LoaderImage = styled.img`
    height: 40px;
`;

const Wrapper = styled.div`
    width: 1000px;
    margin: 0 auto;
`;

const SelNamePlaceholder = styled.div`
    font-weight: bold;
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
`;

const MoLabel = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
`;

const MoVal = styled.div`
    //color: white;
    color: black;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
`;

const ProofVal = styled(MoVal)`
    font-size: 12px;
    text-overflow: unset;
    white-space: unset;
    overflow-x: auto;
    word-break: break-all;
`;

const MoRow = styled.div`
    margin-bottom: 20px;
`;
