import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ReactHelper from "../../helpers/ReactHelper";

export default function CoolModal(props) {
    const {
        onClose = () => {

        },
        width = 460
    } = props;
    const ref = useRef();
    ReactHelper.useOnClickOutside(ref, () => onClose());

    return (
        <Wrapper >

            <ClosePlaceholder>
                <CloseImg src={require('../../assets/images/mdi_close_.svg')} onClick={() => {
                    onClose();
                }}/>
            </ClosePlaceholder>

            <InnerPlaceholder ref={ref} width={width} >
                {props.children}
            </InnerPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: fixed;
    z-index: 1000000;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const InnerPlaceholder = styled.div`
    box-sizing: border-box;
    width: ${props => (props.width == undefined ? 460 : props.width)}px;
    padding: 30px;
    background: #171822;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    @media(max-width: 460px){
      width: 100%;
    }
`;

const ClosePlaceholder = styled.div`
    text-align: center;
    margin-bottom: 30px;
`;

const CloseImg = styled.img`
    width: 34px;
    height: 34px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
