import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

export default function AboutSection(props) {


    return (
        <Wrapper id={'about_section'} >

            <Left>
                <LeftHeading>
                    Что такое депонирование?
                </LeftHeading>
                <LeftContent>
                    Онлайн-услуга, в рамках которой генерируется защищенное от несанкционированного доступа
                    Свидетельство,
                    подтверждающее существование объекта интеллектуальной деятельности в конкретный момент времени,
                    а также факт его неизменности с этой даты.
                </LeftContent>
                <LeftSubContent>
                    <LeftButton onClick={() => {
                        CommonHelper.linkTo('/login');
                    }}>
                        Депонировать актив
                    </LeftButton>
                </LeftSubContent>
            </Left>

            <Right>
                <DepoImg src={require('../../../assets/images/mini_depo.png')}/>
            </Right>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 80px;
`;

const LeftHeading = styled.div`
    color: #465E6F;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
`;

const LeftContent = styled.div`
    color: #0A1B32;
    opacity: 0.5;
    font-size: 22px;
    line-height: 32px;
    margin-top: 20px;
`;

const LeftSubContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
`;

const LeftButton = styled.div`
    background: #EAF3FF;
    border-radius: 12px;
    padding-left: 50px;
    padding-right: 50px;
    color: #0072BC;
    font-weight: bold;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const Left = styled.div`
    width: 540px;
    box-sizing: border-box;
`;

const Right = styled.div`
    flex: 1;
`;

const DepoImg = styled.img`
    width: 100%;
`;
