import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import * as actions from '../../../redux/actions/ProjectsActions'
import CommonHelper from "../../../helpers/CommonHelper";

const loadingGif = require('../../../assets/images/loading_gif.gif');

export default function ProjectViewPanel(props) {
    const {id} = props;

    const {loading, project} = useMappedState(useCallback(state => {
        return {
            project: state.projects.projectsMap.get(id),
            loading: state.users.loading || state.projects.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getProject(id));
    }, [id]);

    if (project == undefined) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>


            {project.name == undefined || project.name.trim() == '' ? null :
                <SelNamePlaceholder>
                    {project.name}
                </SelNamePlaceholder>
            }

            {CommonHelper.isImageUrl(project.originalUrl) == false ? null :
                <div>
                    <TopDialogImagePlaceholder src={project.originalUrl}/>
                </div>
            }

            <MoRow>
                <MoLabel>
                    Хеш
                </MoLabel>
                <MoVal style={{fontSize: 12}}>
                    {project.hash}
                </MoVal>
            </MoRow>

            <MoRow>
                <MoLabel>
                    Ссылка на оригинал
                </MoLabel>
                <MoLink href={project.originalUrl}
                        target={'_blank'}>
                    {'Скачать файл'}
                </MoLink>
            </MoRow>

            <MoRow>
                <MoLabel>
                    Сертификат
                </MoLabel>
                {project.binaryProof == undefined ?
                    <MoVal>
                        <LoaderImage src={loadingGif}/>
                    </MoVal> :
                    <MoLink href={`https://patent.tsu.ru/api/project/${project.id}/certificate?t=${+new Date()}`}
                            target={'_blank'}>
                        {'Download certificate'}
                    </MoLink>
                }
            </MoRow>


            <MoRow>
                <MoLabel>
                    Сжатое доказательство
                </MoLabel>
                {project.binaryProof == undefined ?
                    <MoVal>
                        <LoaderImage src={loadingGif}/>
                    </MoVal> :
                    <ProofVal>
                        {project.binaryProof}
                    </ProofVal>
                }
            </MoRow>

            <MoRow>
                <MoLabel>
                    Транзакция в блокчейне Биткойна
                </MoLabel>
                {project.binaryProof == undefined ?
                    <MoVal>
                        <LoaderImage src={loadingGif}/>
                    </MoVal>
                    :
                    <MoLink href={`https://node1.patent.tsu.ru/api/v1/transactions/${project.bgChainTxId}`}
                            target={'_blank'}>
                        {`https://node1.patent.tsu.ru/api/v1/transactions/${project.bgChainTxId}`}
                    </MoLink>
                }
            </MoRow>


        </Wrapper>
    );
}

const TopDialogImagePlaceholder = styled.div`
    height: 200px;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});
    background-color: rgba(211,211,211,0.05);
    margin-bottom: 10px;
    border-radius: 4px;
`;

const MoLink = styled.a`
    //color: white;
    color: black;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
`;

const LoaderImage = styled.img`
    height: 40px;
`;

const Wrapper = styled.div`
    
`;

const SelNamePlaceholder = styled.div`
    font-weight: bold;
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
`;

const MoLabel = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
`;

const MoVal = styled.div`
    //color: white;
    color: black;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
`;

const ProofVal = styled(MoVal)`
    font-size: 12px;
    text-overflow: unset;
    white-space: unset;
    overflow-x: auto;
    word-break: break-all;
`;

const MoRow = styled.div`
    margin-bottom: 20px;
`;
