import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../helpers/CommonHelper";

import ls from 'local-storage'
import UsersAPI from "../../api/UsersAPI";

const NAV_ITEMS = [
    {
        label: 'Мои файлы',
        name: 'index',
        url: '/'
    },
    {
        label: 'Проверить свидетельство',
        name: 'verification',
        url: '/verification'
    },
    // {
    //     label: 'База знаний',
    //     name: 'knowledge',
    //     url: '/knowledge'
    // },
    // {
    //     label: 'Тарифы',
    //     name: 'pricing',
    //     url: '/pricing'
    // }
];

const ADMIN_NAV_ITEMS = [
    {
        label: 'Главная',
        name: 'index',
        url: '/',
        icon: require('../../assets/images/home-icon-silhouette.svg')
    },
    {
        label: 'Пользователи',
        name: 'users',
        url: '/users',
        icon: require('../../assets/images/group-d.svg')
    },
    {
        label: 'Настройка типов ридов',
        name: 'rids',
        url: '/rids',
        icon: require('../../assets/images/group-d.svg')
    },
    {
        label: 'Настройки',
        name: 'settings',
        url: '/settings'
    }
];


export default function UniTemplate(props) {
    let {active} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, isAdmin, userRole} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = (currentUser != undefined && currentUser.userRole == 'admin');
        return {
            currentUser: currentUser,
            loading: state.users.loading,
            isAdmin: isAdmin,
            userRole: (currentUser == undefined) ? 'guest' : currentUser.userRole
        }
    }, []));

    let leftItems = (userRole == 'admin') ? ADMIN_NAV_ITEMS : NAV_ITEMS;

    return (
        <Wrapper>

            <Header>
                <HeaderInner>
                    <TopLeftPlaceholder>
                        <Logo src={require('../../assets/images/pat_tsu_logo.svg')} onClick={() => {
                            // dispatch(active.si)
                            ls('force_landing', true);
                            CommonHelper.linkTo('/');
                            window.location.reload();
                        }}/>
                        <LogoText onClick={() => {
                            ls('force_landing', true);
                            CommonHelper.linkTo('/');
                            window.location.reload();
                        }}>
                            <LogoTextTop>
                                PATENT TSU
                            </LogoTextTop>
                            <LogoTextBottom>
                                регистратор интеллектулальной собтственности
                            </LogoTextBottom>
                        </LogoText>
                        <TopLeftLinksPlaceholder>
                            {leftItems.map((a, i) => {
                                let isSelected = (a.name == active);
                                return (
                                    <TopLeftLinkItem selected={isSelected} key={`${i}_${a.name}`} onClick={() => {
                                        CommonHelper.linkTo(a.url);
                                    }}>
                                        {a.label}
                                    </TopLeftLinkItem>
                                )
                            })}

                        </TopLeftLinksPlaceholder>
                    </TopLeftPlaceholder>
                    <TopRightPlaceholder>
                        <ProfileSection>
                            <ProfileImg src={require('../../assets/images/Profile.svg')}
                                        onClick={() => {
                                            CommonHelper.linkTo(`/profile`);
                                        }}/>
                            <ProfileHiddenSection>
                                <DropRow>
                                    <LogoutSpan onClick={() => {
                                        CommonHelper.linkTo('/profile')
                                    }}>
                                        профиль
                                    </LogoutSpan>
                                </DropRow>

                                <DropRow>
                                    <LogoutSpan onClick={async () => {
                                        await UsersAPI.parselessLogout();
                                        CommonHelper.linkTo('/');
                                        window.location.reload();
                                    }}>
                                        выход
                                    </LogoutSpan>
                                </DropRow>

                            </ProfileHiddenSection>
                        </ProfileSection>

                    </TopRightPlaceholder>
                </HeaderInner>
            </Header>

            <ContentPlaceholder>
                {props.children}
            </ContentPlaceholder>

        </Wrapper>
    );
}

const headerHeight = 80;
const breakWidth = 1200;

const DropRow = styled.div`
    margin-bottom: 10px;
    :last-of-type{
      margin-bottom: 0px;
    }
`;

const LogoutSpan = styled.div`
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
`;

const ProfileSection = styled.div`
    position: relative;
`;

const ProfileHiddenSection = styled.div`
    position: absolute;
    top: 25px;
    right: -10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    width: 80px;
    z-index: 10;  
    background: white;
    display: none;
    border: 1px solid lightgrey;
    border-radius: 4px;
    ${ProfileSection}:hover & {
      display: block;
    }
`;

const ProfileLogoutSection = styled.div`
    display: none;
`;

const ProfileImg = styled.img`
    cursor: pointer;
    height: 24px;
    :hover{
      opacity: 0.8;
    }
`;

const TopLeftLinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopLeftLinkItem = styled.div`
    margin-right: 40px;
    cursor: pointer;
    color: #465E6F;
    font-size: 18px;
    line-height: 32px;
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
`;

const Wrapper = styled.div`
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background: #F5F5FB;
    overflow: hidden;
`;

const Header = styled.div`
    height: ${headerHeight}px;
    box-sizing: border-box;
    width: 100vw;
    background: white;
    border-bottom: 1px solid #EAEDF3;
`;

const HeaderInner = styled.div`
    height: 100%;
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media(max-width: ${breakWidth}px){
      width: 100%;
    }
`;

const TopLeftPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const TopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Logo = styled.img`
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
`;

const LogoText = styled.div`
    margin-right: 80px;
    cursor: pointer;
`;

const LogoTextTop = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #0072BC;  
    letter-spacing: 10px;
`;

const LogoTextBottom = styled.div`
    font-size: 10px;
    line-height: 10px;
    color: grey;
    opacity: 0.8;
`;

const ContentPlaceholder = styled.div`
    height: calc(100vh - ${headerHeight}px);
    max-height: calc(100vh - ${headerHeight}px);
    overflow-y: auto;
`;
