import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'


export default function UpdateProfileForm(props) {
    const {
        onSave = d => {

        }, loading = false
    } = props;

    const [middleName, setMiddleName] = useState(props.middleName == undefined ? '' : props.middleName);
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [birthDateString, setBirthDateString] = useState(props.birthDateString == undefined ? '' : props.birthDateString);
    const [inn, setInn] = useState(props.inn == undefined ? '' : props.inn);
    const [livingPlace, setLivingPlace] = useState(props.livingPlace == undefined ? '' : props.livingPlace);

    const [error, setError] = useState(undefined);
    const [innIsBad, setInnIsBad] = useState(false);
    const [bdIsBad, setBdIsBad] = useState(false);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Фамилия
                </Label>
                <Input bad={lastName != undefined && lastName.length > 0 && !isValidCiryl(lastName)} value={lastName}
                       onChange={evt => {
                           setLastName(evt.target.value);
                       }}/>
            </Field>

            <Field>
                <Label>
                    Имя
                </Label>
                <Input bad={firstName != undefined && firstName.length > 0 && !isValidCiryl(firstName)}
                       value={firstName} onChange={evt => {
                    setFirstName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Отчество
                </Label>
                <Input bad={middleName != undefined && middleName.length > 0 && !isValidCiryl(middleName)}
                       value={middleName} onChange={evt => {
                    setMiddleName(evt.target.value);
                }}/>
            </Field>


            <Field>
                <Label>
                    Дата рождения
                </Label>
                <Input value={birthDateString}
                       bad={bdIsBad}
                       placeholder={'ДД.MM.ГГГГ'} onChange={evt => {
                    setBirthDateString(evt.target.value);
                    setError(undefined);
                    setBdIsBad(false);
                }}/>
            </Field>

            <Field>
                <Label>
                    ИНН
                </Label>
                <Input placeholder={'Номер ИНН'}
                       value={inn}
                       bad={innIsBad}
                       onChange={evt => {
                           let ss = evt.target.value.replace(/ /g, '').replace(/\D/g,'');
                           let regex = /[0-9]|\./;
                           if (!regex.test(ss) && ss != '') {
                               return;
                           }
                           setInn(ss);
                           setInnIsBad(false);
                           setError(undefined);
                       }}/>
            </Field>

            <Field>
                <Label>
                    Место жительства
                </Label>
                <Input placeholder={'г. Москва, Тверская 6, кв. 32'}
                       value={livingPlace}
                       onChange={evt => {
                           setLivingPlace(evt.target.value);
                       }}/>
            </Field>

            {error == undefined ? null :
                <div style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: 16}}>
                    {error}
                </div>
            }

            <BottomField>
                <Button onClick={() => {
                    setError(undefined);
                    let isGoodInn = validateInn(inn, {});
                    let isGoodBirthday = moment(birthDateString, 'DD.MM.YYYY', true).isValid();
                    if (isGoodInn == false) {
                        setError('ИНН введен неверно');
                        setInnIsBad(true);
                        return;
                    }
                    if (isGoodBirthday == false) {
                        setError('Дата рождения введена неверно. Используйте формат ДД.ММ.ГГГГ.')
                        setBdIsBad(true);
                        return;
                    }
                    if (!isValidCiryl(firstName) || !isValidCiryl(lastName) || !isValidCiryl(middleName)) {
                        setError('ФИО должно содержать только кириллицу');
                        return;
                    }

                    onSave({firstName, lastName, inn, birthDateString, livingPlace, middleName})
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <span>Сохранить</span>
                    }
                </Button>
            </BottomField>

        </Wrapper>
    );
}

let isValidCiryl = function (s) {
    return s && /^[а-яА-ЯЁё ]+$/.test(s);
};

const Wrapper = styled.div`
    margin: 0 auto;
    width: 510px;
    box-sizing: border-box;
    margin-bottom: 20px;
`;

const Field = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
`;

const Input = styled.input`
    background: #FFFFFF;
    border: 2px solid ${props => (props.bad == true ? 'red' : 'transparent')};
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    height: 50px;
    width: 100%;
    padding-left: 15px;
    
    font-weight: bold;
    
    font-size: 17px;
    line-height: 23px;
    
    color: #465E6F;
    
    :focus{
      border: 2px solid rgba(255, 255, 255, 0.03);
    }
`;

const BottomField = styled.div`
    //display: flex;
    //flex-direction: row;
    //align-items: center;
    //justify-content: flex-end;
    margin-top: 15px;
`;

const Button = styled.div`
    background: #0072BC;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 46px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
  
    cursor: pointer;
    
    :hover{
      opacity: 0.9;
    }
`;

function validateInn(inn, error) {
    let result = false;
    if (typeof inn === 'number') {
        inn = inn.toString();
    } else if (typeof inn !== 'string') {
        inn = '';
    }
    if (!inn.length) {
        error.code = 1;
        error.message = 'ИНН пуст';
    } else if (/[^0-9]/.test(inn)) {
        error.code = 2;
        error.message = 'ИНН может состоять только из цифр';
    } else if ([10, 12].indexOf(inn.length) === -1) {
        error.code = 3;
        error.message = 'ИНН может состоять только из 10 или 12 цифр';
    } else {
        let checkDigit = function (inn, coefficients) {
            let n = 0;
            for (let i in coefficients) {
                n += coefficients[i] * inn[i];
            }
            return parseInt(n % 11 % 10);
        };
        switch (inn.length) {
            case 10:
                let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if (n10 === parseInt(inn[9])) {
                    result = true;
                }
                break;
            case 12:
                let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
                    result = true;
                }
                break;
        }
        if (!result) {
            error.code = 4;
            error.message = 'Неправильное контрольное число';
        }
    }
    return result;
}
