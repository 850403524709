import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {API_ENDPOINT} from "../../constants/config";

import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import AllRidsPanel from "../rids/panels/AllRidsPanel";
import UpdateFaqPanel from "../faq/panels/UpdateFaqPanel";

export default function AdminFaqApp() {

    return (
        <SubtitlesTemplate active={'faq'}>

            <h2>
                FAQ
            </h2>

            <UpdateFaqPanel/>

        </SubtitlesTemplate>
    );
}

const DownloadAppPlaceholder = styled.div`
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const Link = styled.a`
    color: white;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;
