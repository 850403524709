import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import RidTypeSelectorTool from "../workspace/tools/RidTypeSelectorTool";
import UpdateProjectTool from "../workspace/tools/UpdateProjectTool";

export default function DevApp() {

    return (
        <Wrapper>
            {/*<RidTypeSelectorTool/>*/}
            <UpdateProjectTool/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const EditorPlaceholder = styled.div`
    height: 100vh;
    width: 100vw;
`;

const CanvPlaceholder = styled.div`
    background: brown;
    width: 500px;
    height: 500px;
    margin: 0 auto;
    margin-top: 40px;
`;
