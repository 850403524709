import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import PickPlanPanel from "../billing/panels/PickPlanPanel";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserSubscriptionsPanel from "../subscriptions/panels/UserSubscriptionsPanel";
import UserBillingPanel from "../billing/panels/UserBillingPanel";
import UniTemplate from "../templates/UniTemplate";

export default function UserPricingApp() {

    return (
        <UniTemplate active={'pricing'}>

            <Wrapper>

                {/*<UserBillingPanel heading={'Тарифы'} />*/}
                <Wrapper>
                    Раздел находится в разработке
                </Wrapper>

            </Wrapper>


        </UniTemplate>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 40px;
    color: black;
    @media(max-width: 1200px){
      width: 100%;
    }
`;
