import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';


const ITEMS = [
    {
        name: 'Технология',
        image: require('../../../assets/images/objs/Work.svg'),
        list: [
            'Секрет производства',
            'Ноу-Хау',
            'Техническое решение'
        ]
    },
    {
        name: 'Звук',
        image: require('../../../assets/images/objs/Voice_2.svg'),
        list: [
            'Мелодия',
            'Фонограмма',
            'Музыкальное произведение'
        ]
    },
    {
        name: 'Текст',
        image: require('../../../assets/images/objs/Document.svg'),
        list: [
            'Учебное пособие',
            'Научная работа',
            'Идея'
        ]
    },
    {
        name: 'Изображение',
        image: require('../../../assets/images/objs/Image.svg'),
        list: [
            'Логотип',
            'Чертеж',
            'Фотография'
        ]
    },
    {
        name: 'Видео',
        image: require('../../../assets/images/objs/Video.svg'),
        list: [
            'Видеокурс',
            'Видеолекция',
            'Бизнес-трекинг'
        ]
    },
    {
        name: 'Код',
        image: require('../../../assets/images/objs/Password.svg'),
        list: [
            'База данных',
            'программа для ЭВМ',
            'Образовательная программа'
        ]
    },


];


export default function ObjectsSections(props) {


    return (
        <Wrapper>

            <TopPlaceholder>
                Объекты депонирования
            </TopPlaceholder>

            <BottomPlaceholder>
                {ITEMS.map((it, i) => {
                    return (
                        <TripletItem key={i}>
                            <ItemLeft>
                                <ItemLeftImage src={it.image}/>
                                <ItemLeftText>
                                    {it.name}
                                </ItemLeftText>
                            </ItemLeft>
                            <ItemLeftRight>
                                {it.list.map((a, j) => {
                                    return (
                                        <MiniItem key={j}>
                                            {a}
                                        </MiniItem>
                                    )
                                })}
                            </ItemLeftRight>
                        </TripletItem>
                    )
                })}
            </BottomPlaceholder>

        </Wrapper>
    );
}

const MiniItem = styled.div`
    color: #0A1B32;
    opacity: 0.5;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
`;

const ItemLeft = styled.div`
    vertical-align: top;

    padding-top: 40px;
    padding-bottom: 40px;
    width: 170px;
    
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    background: #FFFFFF;
    border: 1px solid #D9E1FF;
    border-radius: 18px;
    display: inline-flex;
`;

const ItemLeftText = styled.div`
    color: #202224;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
`;

const ItemLeftImage = styled.img`
    width: 64px;
`;

const Wrapper = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    padding-top: 60px;
    padding-bottom: 60px;
`;

const TopPlaceholder = styled.div`
    color: #465E6F;
    text-align: center;
    margin-bottom: 40px;
    font-size: 36px;
    font-weight: bold;
    @media(max-width: 720px){
      font-size: 32px;
    }
`;

const BottomPlaceholder = styled.div`
    
`;

const TripletItem = styled.div`
    width: 33.33%;
    box-sizing: border-box;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    display: inline-flex;
    vertical-align: top;
    margin-bottom: 50px;
    @media(max-width: 720px){
      display: flex;
      width: 100%;
    }
`;


const ItemLeftRight = styled.div`
    display: inline-block;
    width: calc(100% - 170px - 40px);
`;
