import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";


import ls from 'local-storage'
import NiceModal from "../../modals/NiceModal";
import VerificationTool from "../../verification/tools/VerificationTool";

const BG = require('../../../assets/images/g_group_185.jpg')

const ITEMS = [
    {
        image: require('../../../assets/images/cam_tr_1.svg'),
        color: '#59CDFF',
        name: 'Видео'
    },
    {
        image: require('../../../assets/images/cam_tr_2.svg'),
        color: '#F7BCBC',
        name: 'Аудио'
    },
    {
        image: require('../../../assets/images/cam_tr_3.svg'),
        color: '#BB6BD9',
        name: 'Текст'
    },

]

const goToId = id => {
    try {
        if (id != undefined) {
            document.querySelector(`#${id}`).scrollIntoView({
                behavior: 'smooth'
            });
        }
    } catch (exc) {

    }
}

export default function TopSection(props) {
    const [checkVisible, setCheckVisible] = useState(false);

    let {currentUser, loading, isAdmin, userRole} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = (currentUser != undefined && currentUser.userRole == 'admin');
        return {
            currentUser: currentUser,
            loading: state.users.loading,
            isAdmin: isAdmin,
            userRole: (currentUser == undefined) ? 'guest' : currentUser.userRole
        }
    }, []));

    return (
        <Wrapper>
            <TopLinePlaceholder>

                <Logo src={require('../../../assets/images/patent_logo.svg')}/>

                <TopRightPlaceholder>

                    <TopRightLink onClick={() => {
                        goToId('about_section');
                    }}>
                        О нас
                    </TopRightLink>

                    <TopRightLink onClick={() => {
                        setCheckVisible(true);
                    }}>
                        Проверить свидетельство
                    </TopRightLink>

                    <TopRightLink onClick={() => {
                        goToId('know_section');
                    }}>
                        База знаний
                    </TopRightLink>

                    {/*<TopRightLink>*/}
                    {/*Новости*/}
                    {/*</TopRightLink>*/}

                    <TopRightLink onClick={() => {
                        ls('login_mode', 'login');
                        ls('force_landing', false);
                        if (currentUser == undefined) {
                            CommonHelper.linkTo(`/login`);
                        } else {
                            window.location.reload();
                        }
                    }}>
                        Вход
                    </TopRightLink>

                    <SignupButton onClick={() => {
                        ls('login_mode', 'signup');
                        ls('force_landing', false);
                        if (currentUser == undefined) {
                            CommonHelper.linkTo(`/login`);
                        } else {
                            window.location.reload();
                        }
                    }}>
                        Регистрация
                    </SignupButton>

                </TopRightPlaceholder>

            </TopLinePlaceholder>

            {checkVisible == false ? null :
                <NiceModal width={Math.min(620, window.innerWidth)} onClose={() => {
                    setCheckVisible(false)
                }}>

                    <div style={{backgroundColor: '#F7F8F9', paddingTop: 15, paddingBottom: 15}}>
                        <VerificationTool isMini={true} />
                    </div>

                </NiceModal>
            }

            <ContentPlaceholder>

                <MottoPlaceholder>
                    <div>
                        Сервис цифрового
                    </div>
                    <div>
                        депонирования
                    </div>
                </MottoPlaceholder>

                <MottoTripletsPlaceholder>
                    {ITEMS.map((it, i) => {
                        return (
                            <MottoTripletWrapper>
                                <MottoTriplet key={i} color={it.color}>
                                    <TrImg src={it.image}/>
                                </MottoTriplet>
                                <MottoTripletText>
                                    {it.name}
                                </MottoTripletText>
                            </MottoTripletWrapper>
                        )
                    })}
                </MottoTripletsPlaceholder>

                <ActionButtonPlaceholder>
                    <ActionButton onClick={() => {
                        CommonHelper.linkTo('/login');
                    }}>
                        Депонировать актив
                    </ActionButton>
                </ActionButtonPlaceholder>

            </ContentPlaceholder>

        </Wrapper>
    );
}

const TrImg = styled.img`
    width: 32px;
`;

const ActionButton = styled.div`
    padding-left: 90px;
    padding-right: 90px;
    color: #0072BC;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: bold;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 8px;
    :hover{
      opacity: 0.8;
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    background-image: url(${BG});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 680px;
`;

const MottoPlaceholder = styled.div`
    font-weight: bold;
    font-size: 40px;
    line-height: 54px;
    color: #FFFFFF;
    margin-top: 60px;
    @media(max-width: 720px){
      text-align: center;
    }
`;

const MottoTripletsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 30px;
    @media(max-width: 720px){
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
`;

const ActionButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    @media(max-width: 720px){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;

const MottoTripletWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
`;

const MottoTripletText = styled.div`
    color: white;
    font-weight: bold;
    font-size: 15px;
    margin-top: 25px;
`;

const MottoTriplet = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${props => props.color};
    width: 88px;
    height: 88px;
    box-sizing: border-box;
    border-radius: 1000px;
    border: 10px solid white;
`;

const ContentPlaceholder = styled.div`
    width: 1100px;
    margin: 0 auto;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media(max-width: 1100px){
      width: 100%;
    }
`;

const TopLinePlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 1100px;
    margin: 0 auto;
    padding-top: 25px;
    @media(max-width: 1100px){
      width: 100%;
    }
    @media(max-width: 720px){
      display: block;
    }
`;

const Logo = styled.img`
    height: 48px;
    @media(max-width: 720px){
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
`;

const TopRightLink = styled.div`
    color: white;
    font-size: 15px;
    line-height: 18px;
    margin-right: 50px;
    cursor: pointer;
    font-weight: bold;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 720px){
      margin-right: 10px;
      font-size: 13px;
    }
`;

const SignupButton = styled.div`
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    height: 42px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 720px){
      padding-left: 15px;
    padding-right: 15px;
    }
`;


const TopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 720px){
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
    }    
`;
