import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

export default function UpdateSubscriptionForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [price, setPrice] = useState(props.price == undefined ? '0' : props.price)
    const [months_number, setMonths_number] = useState(props.months_number == undefined ? '1' : props.months_number)

    const [paddle_id, setPaddle_id] = useState(props.paddle_id == undefined ? '' : props.paddle_id);

    const [label, setLabel] = useState(props.label == undefined ? '' : props.label)
    const [name, setName] = useState(props.name == undefined ? '' : props.name)
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description)

    const [videos_number, setVideos_number] = useState(props.videos_number == undefined ? '0' : props.videos_number)


    return (
        <Wrapper>

            <Field>
                <Label>
                    Name (integration key)
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Paddle ID
                </Label>
                <Input value={paddle_id} onChange={evt => {
                    setPaddle_id(evt.target.value);
                }}/>
            </Field>


            <Field>
                <Label>
                    Price
                </Label>
                <Input value={price} onChange={evt => {
                    setPrice(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Label
                </Label>
                <Input value={label} onChange={evt => {
                    setLabel(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Period (in months)
                </Label>
                <Input value={months_number} onChange={evt => {
                    setMonths_number(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Description
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Videos number
                </Label>
                <Input value={videos_number} onChange={evt => {
                    setVideos_number(evt.target.value);
                }}/>
            </Field>



            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            name,
                            paddle_id: `${paddle_id}`,
                            label,
                            description,
                            videos_number: +videos_number,
                            price: +price
                        })
                    }}>
                        Save
                    </Button>
                }
            </Field>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
