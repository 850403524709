import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

const haImg = require('../../../assets/images/circle_ha.svg')

export default function HowToSection(props) {
    const {
        items = [
            {
                name: 'Загрузка файла',
                description: 'Результат ИД загружается в защищенное хранилище по зашифрованному каналу связи, информация записывается в распределенный реестр.'
            },
            {
                name: 'Фиксация в блокчейне',
                description: 'Объекту интеллектуальной деятельности ставится в соответсвие идентификатор (хэш). Вся информация об объекте - прозрачна и неизменна.'
            },
            {
                name: 'Свидетельство',
                description: 'Пользователь получает Свидетельство о депонировании с цифровой подписью, штампом даты и времени.'
            }
        ]
    } = props;

    return (
        <Wrapper id={'know_section'} >

            <Heading>
                Как задепонировать файл
            </Heading>

            <TripletsPlaceholder>
                {items.map((it, i) => {
                    return (
                        <TripletItem key={i} src={haImg}>
                            <CirclePlaceholder>
                                <HaCircle>
                                    {+i + 1}
                                </HaCircle>
                            </CirclePlaceholder>
                            <ItemHeading>
                                {it.name}
                            </ItemHeading>
                            <ItemDescription>
                                {it.description}
                            </ItemDescription>
                        </TripletItem>
                    )
                })}
            </TripletsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
`;

const HaCircle = styled.div`
    width: 70px;
    height: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${haImg});
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 40px;
`;

const ItemHeading = styled.div`
    font-size: 24px;
    color: #465E6F;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ItemDescription = styled.div`
    color: #3E4462;
    font-size: 16px;
    line-height: 28px;
    min-height: 112px;
`;

const CirclePlaceholder = styled.div`
    margin-bottom: 20px;
`;

const Heading = styled.div`
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    font-size: 36px;
    color: #465E6F;
`;

const TripletsPlaceholder = styled.div`
    
`;

const TripletItem = styled.div`
    margin: 20px;
    display: inline-block;
    vertical-align: top;
    width: calc(33.33% - 40px);
    background: #FFFFFF;
    box-shadow: 0px 20px 48px rgba(7, 84, 134, 0.05);
    border-radius: 16px;
    padding: 32px;
    box-sizing: border-box;
    @media(max-width: 720px){
      display: block;
      width: 100%;
      margin: 0px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
`;
