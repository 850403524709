import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UserSubscriptionsPanel from "../../subscriptions/panels/UserSubscriptionsPanel";

export default function UserBillingPanel(props) {
    const {heading = 'Billing'} = props;

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopName>
                    {heading}
                </TopName>
                <TopRight>

                </TopRight>
            </TopPlaceholder>

            <Content>
                {/*<UserSubscriptionsPanel/>*/}
            </Content>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Content = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopName = styled.div`
    font-style: normal;
font-weight: bold;
font-size: 34px;
line-height: 46px;
/* identical to box height, or 135% */


color: #FFFFFF;

`;

const TopRight = styled.div`
    
`;
