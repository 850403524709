import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import * as actions from "../../../redux/actions/RidsActions";

import * as projectsActions from '../../../redux/actions/ProjectsActions'
import RidTypeSelectorTool from "./RidTypeSelectorTool";
import {Button} from "../../ui/Button";
import FieldsInputFormTool from "./FieldsInputFormTool";
import UploadVideoPanel from "../../upload/UploadVideoPanel";

import Spin from 'arui-feather/spin'

import {Code} from 'react-content-loader'

const getModeName = mode => {
    return {
        rid_type: 'Выберите тип РИДа',
        info: 'Заполните информацию',
        upload: 'Загрузите файл'
    }[mode]
}

export default function UpdateProjectTool(props) {
    const {
        skipUpload = false,
        onSave = d => {
            console.log('UpdateProjectTool: onSave: d = ', d);
        }
    } = props;

    const [mode, setMode] = useState('rid_type');

    const [originalUrl, setOriginalUrl] = useState(props.originalUrl);
    const [hash, setHash] = useState(props.hash);

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [data, setData] = useState(props.data == undefined ? {} : props.data);

    const [selectedId, setSelectedId] = useState(undefined);

    const {rids = [], ridsMap, currentUserId, loading} = useMappedState(useCallback(state => {
        let rids = state.rids.ridsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp));
        return {
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.rids.loading || state.projects.loading,
            rids: rids,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            ridsMap: state.rids.ridsMap
        }
    }, []));
    const dispatch = useDispatch();
    let selectedItem = (selectedId == undefined) ? undefined : rids.filter(a => (a.id == selectedId))[0];

    useEffect(() => {
        dispatch(actions.loadAllRids());
    }, []);

    let modeName = getModeName(mode);

    return (
        <Wrapper>

            <ModeNamePlaceholder>
                {modeName}
                {/*<Spin visible={loading}/>*/}
            </ModeNamePlaceholder>

            <ActiveModePlaceholder>
                {mode != 'rid_type' ? null :
                    <div>
                        <RidTypeSelectorTool selectedId={selectedId}
                                             onUnselect={() => {
                                                 setSelectedId(undefined);
                                             }}
                                             onSelect={a => {
                                                 setSelectedId(a.id);
                                             }}/>
                        {selectedItem == undefined ? null :
                            <NextButtonPlaceholder>

                                {/*<BackSpan style={{visibility: 'hidden'}} onClick={() => {*/}
                                {/*if (selectedItem.fields != undefined && selectedItem.fields.length > 0) {*/}
                                {/*setMode('info');*/}
                                {/*} else {*/}
                                {/*setMode('rid_type');*/}
                                {/*}*/}
                                {/*}}>*/}
                                {/*назад*/}
                                {/*</BackSpan>*/}

                                {selectedItem.description == undefined ? null :
                                    <SelectedItemDescriptionPlaceholder>
                                        {selectedItem.description}
                                    </SelectedItemDescriptionPlaceholder>
                                }

                                <NextButton onClick={() => {
                                    if (selectedItem.fields != undefined && selectedItem.fields.length > 0) {
                                        setMode('info');
                                    } else {
                                        if (skipUpload == false) {
                                            setMode('upload');
                                        } else {
                                            onSave({
                                                ridId: selectedItem.id
                                            });
                                        }
                                    }
                                }}>
                                    Далее
                                </NextButton>
                            </NextButtonPlaceholder>
                        }
                    </div>
                }

                {mode != 'info' ? null :
                    <React.Fragment>
                        <div style={{width: 640, margin: '0 auto'}}>
                            <FieldsInputFormTool {...selectedItem} data={data} onChange={d => {
                                setData({...data, ...d});
                            }}/>

                        </div>
                        <NextButtonPlaceholder>

                            {/*<BackSpan onClick={() => {*/}
                            {/*setMode('rid_type');*/}
                            {/*}}>*/}
                            {/*назад*/}
                            {/*</BackSpan>*/}

                            <NextButton onClick={() => {
                                if (skipUpload == false) {
                                    setMode('upload');
                                } else {
                                    onSave({
                                        ridId: selectedItem.id,
                                        data: data
                                    });
                                }
                            }}>
                                Далее
                            </NextButton>
                        </NextButtonPlaceholder>

                    </React.Fragment>
                }

                {mode != 'upload' ? null :
                    <React.Fragment>

                        <UploadVideoPanel uploadText={'Загрузить файл'}
                                          onUploaded={async pld => {
                                              setOriginalUrl(pld.url);
                                              setHash(pld.hash);
                                          }}/>


                        <NextButtonPlaceholder>
                            {/*<BackSpan onClick={() => {*/}
                            {/*if (selectedItem.fields != undefined && selectedItem.fields.length > 0) {*/}
                            {/*setMode('info');*/}
                            {/*} else {*/}
                            {/*setMode('rid_type');*/}
                            {/*}*/}
                            {/*}}>*/}
                            {/*назад*/}
                            {/*</BackSpan>*/}

                            {originalUrl == undefined ? null :
                                <NextButton onClick={() => {
                                    onSave({
                                        ridId: selectedItem.id,
                                        data: data,
                                        hash: hash,
                                        originalUrl: originalUrl
                                    });
                                }}>
                                    Далее
                                </NextButton>
                            }

                        </NextButtonPlaceholder>


                    </React.Fragment>
                }

            </ActiveModePlaceholder>

        </Wrapper>
    );
}

const SelectedItemDescriptionPlaceholder = styled.div`
    width: 720px;
    box-sizing: border-box;
    margin-bottom: 20px;
    text-align: justify;
    @media(max-width: 720px){
      width: 100%;
    }
`;

const NextButton = styled.div`
    background: #0072BC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 192px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Wrapper = styled.div`
    padding: 10px;
`;

const ActiveModePlaceholder = styled.div`
    
`;

const BackSpan = styled.span`
    cursor: pointer;
    opacity: 0.8;
    :hover{
      opacity: 1;
    }
`;


const NextButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;

const ModeNamePlaceholder = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    padding-bottom: 10px;
`;
