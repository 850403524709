import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UploadVideoPanel from "../../upload/UploadVideoPanel";
import * as actions from "../../../redux/actions/ProjectsActions";
import ProjectItemView from "../tools/ProjectItemView";
import CommonHelper from "../../../helpers/CommonHelper";

import CoolModal from '../../modals/CoolModal'
import UpdateProjectNameForm from "../forms/UpdateProjectNameForm";
import UpdateProjectForm from "../forms/UpdateProjectForm";
import UpdateProjectsPreviewDaemon from "./UpdateProjectsPreviewDaemon";
import UpdateProjectTool from "../tools/UpdateProjectTool";
import NiceModal from "../../modals/NiceModal";

const loadingGif = require('../../../assets/images/loading_gif.gif')

// https://patent.tsu.ru/api/project/${selectedProject.id}/certificate?t=${+new Date()}`}

let ie = a => ((a == undefined) || (`${a}`.replace(/ /g, '').length == 0));

export default function UserWorkspacePanel(props) {
    const {userId} = props;

    const {loading, projects, isAdmin, currentUserId, uId, currentUser} = useMappedState(useCallback(state => {
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        return {
            loading: state.users.loading || state.projects.loading,
            projects: state.projects.projectsMap.toArray().filter(a => (a.userId == uId)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            uId: uId
        }
    }, []));

    const [error, setError] = useState(undefined);

    const dispatch = useDispatch();
    const [progressData, setProgressData] = useState(undefined);
    const [uploading, setUploading] = useState(false);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [mode, setMode] = useState('rename');
    useEffect(() => {
        dispatch(actions.loadUserProjects(uId));
    }, [uId]);

    let selectedProject = (selectedId == undefined) ? undefined : projects.filter(a => (a.id == selectedId))[0];

    if (currentUser == undefined) {
        return null;
    }

    console.log('UserWorkspacePanel: render: currentUser = ', currentUser);

    let {firstName, lastName, middleName, inn, livingPlace} = currentUser;
    let needToFill = (ie(firstName) || ie(lastName) || ie(middleName) || ie(inn) || ie(livingPlace));

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopName>
                    Мои файлы
                </TopName>
                <TopRight>
                    <TopRightNewButton onClick={() => {
                        if (needToFill == true) {
                            // CommonHelper.linkTo('/profile');
                            setError('Заполните профиль перед депонированием.');
                        } else {
                            setAddModalVisible(true);
                        }
                    }}>
                        Загрузить новый файл
                    </TopRightNewButton>
                </TopRight>
            </TopPlaceholder>

            <Content>
                {/*{uId != currentUserId ? null :*/}

                {/*<InlineBox>*/}
                {/*<UploadVideoPanel onUploaded={async pld => {*/}
                {/*let pr = await dispatch(actions.createProject({*/}
                {/*userId: currentUserId,*/}
                {/*originalUrl: pld.url,*/}
                {/*hash: pld.hash*/}
                {/*}));*/}
                {/*setMode('rename');*/}
                {/*setSelectedId(pr.project.id);*/}
                {/*// CommonHelper.linkTo(`/project/${pr.project.id}`);*/}
                {/*}}*/}
                {/*onStart={() => {*/}
                {/*setUploading(true);*/}
                {/*}}*/}
                {/*onProgress={d => {*/}
                {/*setProgressData(d);*/}
                {/*}}*/}
                {/*/>*/}
                {/*</InlineBox>*/}
                {/*}*/}


                {projects.map((pr, j) => {
                    return (
                        <InlineBox key={`${pr.id}_${j}`}>
                            <ProjectItemView {...pr}
                                             onGo={() => {
                                                 setMode('view');
                                                 setSelectedId(pr.id);
                                             }}
                                             onDelete={async () => {
                                                 if (window.confirm('Are you sure?') == false) {
                                                     return;
                                                 }
                                                 await dispatch(actions.deleteProject(pr.id))
                                             }}
                                             onShare={() => {

                                             }}
                                             onDownload={() => {

                                             }}
                                             onRename={() => {
                                                 setMode('rename');
                                                 setSelectedId(pr.id);
                                             }}
                                             onView={() => {
                                                 // let url = `https://grabber.website/z/websites/${pr.id}/`;
                                                 // let win = window.open(url, '_blank');
                                                 // win.focus();
                                                 setMode('view');
                                                 setSelectedId(pr.id);
                                             }}
                            />
                            {pr.bgChainTxId != undefined ? null :
                                <LoaderPlaceholder>
                                    <LoaderImage src={loadingGif}/>
                                </LoaderPlaceholder>
                            }
                        </InlineBox>
                    )
                })}

            </Content>

            {error == undefined ? null :
                <NiceModal onClose={() => {
                    setError(undefined);
                }}>
                    <div style={{padding: 20, textAlign: 'center'}}>
                        <div >
                            {error}
                        </div>
                        <div style={{marginTop: 10}}>
                            <TopRightNewButton onClick={() => {
                                CommonHelper.linkTo('/profile');
                            }}>
                                Перейти к заполнению
                            </TopRightNewButton>
                        </div>
                    </div>


                </NiceModal>
            }

            {addModalVisible == false ? null :
                <FullPageOverlay>
                    <FullPageOverlayInner>

                        <FullPageCloserPlaceholder>
                            <CloseFpImg src={require('../../../assets/images/fp_close_cl.svg')} onClick={() => {
                                setAddModalVisible(false);
                            }}/>
                        </FullPageCloserPlaceholder>

                        {/*<UpdateProjectForm*/}
                        {/*buttonName={'Add website'}*/}
                        {/*loading={loading}*/}
                        {/*onSave={async d => {*/}
                        {/*await dispatch(actions.createProject({userId: currentUserId, ...d}));*/}
                        {/*setAddModalVisible(false);*/}
                        {/*}}/>*/}

                        <UpdateProjectTool loading={loading}
                                           onSave={async d => {
                                               await dispatch(actions.createProject({
                                                   userId: currentUserId, ...d,
                                                   name: `Без названия`
                                               }))
                                               setAddModalVisible(false);
                                           }}/>

                    </FullPageOverlayInner>
                </FullPageOverlay>
            }

            {selectedProject == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <div style={{padding: 20, color: 'black'}}>
                        {mode != 'rename' ? null :
                            <UpdateProjectNameForm {...selectedProject} loading={loading} onSave={async d => {
                                await dispatch(actions.updateProject({id: selectedId, ...d}));
                                setSelectedId(undefined);
                            }}/>
                        }

                        {mode != 'view' ? null :
                            <div>

                                {selectedProject.name == undefined || selectedProject.name.trim() == '' ? null :
                                    <SelNamePlaceholder>
                                        {selectedProject.name}
                                    </SelNamePlaceholder>
                                }

                                {CommonHelper.isImageUrl(selectedProject.originalUrl) == false ? null :
                                    <div>
                                        <TopDialogImagePlaceholder src={selectedProject.originalUrl}/>
                                    </div>
                                }

                                <MoRow>
                                    <MoLabel>
                                        Хеш
                                    </MoLabel>
                                    <MoVal style={{fontSize: 12}}>
                                        {selectedProject.hash}
                                    </MoVal>
                                </MoRow>

                                <MoRow>
                                    <MoLabel>
                                        Оригинал
                                    </MoLabel>
                                    <MoLink href={selectedProject.originalUrl}
                                            target={'_blank'}>
                                        {'Скачать файл'}
                                    </MoLink>
                                </MoRow>

                                <MoRow>
                                    <MoLabel>
                                        Сертификат
                                    </MoLabel>
                                    {selectedProject.bgChainTxId == undefined ?
                                        <MoVal>
                                            <LoaderImage src={loadingGif}/>
                                        </MoVal> :
                                        <MoVal style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                            CommonHelper.linkTo(`/#/cert/${selectedProject.id}`, true);
                                        }}>
                                            {'Скачать сертификат'}
                                        </MoVal>
                                    }
                                </MoRow>


                                {/*<MoRow>*/}
                                {/*    <MoLabel>*/}
                                {/*        Сжатое доказательство*/}
                                {/*    </MoLabel>*/}
                                {/*    {selectedProject.bgChainTxId == undefined ?*/}
                                {/*        <MoVal>*/}
                                {/*            <LoaderImage src={loadingGif}/>*/}
                                {/*        </MoVal> :*/}
                                {/*        <ProofVal>*/}
                                {/*            {selectedProject.binaryProof}*/}
                                {/*        </ProofVal>*/}
                                {/*    }*/}
                                {/*</MoRow>*/}

                                <MoRow>
                                    <MoLabel>
                                        Blockchain TX
                                    </MoLabel>
                                    {selectedProject.bgChainTxId == undefined ?
                                        <MoVal>
                                            <LoaderImage src={loadingGif}/>
                                        </MoVal>
                                        :
                                        <MoLink href={`https://node1.patent.tsu.ru/api/v1/transactions/${selectedProject.bgChainTxId}`}
                                                target={'_blank'}>
                                            {`https://node1.patent.tsu.ru/api/v1/transactions/${selectedProject.bgChainTxId}`}
                                        </MoLink>
                                    }
                                </MoRow>

                            </div>
                        }
                    </div>


                </NiceModal>
            }

            <UpdateProjectsPreviewDaemon/>

        </Wrapper>
    );
}

const CloseFpImg = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const FullPageOverlay = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    background: #F5F5FB;
`;

const FullPageOverlayInner = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;  
    padding-top: 100px;
`;

const FullPageCloserPlaceholder = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
`;

const TopRightNewButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 6px;
    background: #0072BC;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    cursor: pointer;
    height: 50px;
    font-weight: bold;
    :hover{
      opacity: 0.9;
    }
`;

const TopDialogImage = styled.img`
    width: 70%;
`;

const LoaderImage = styled.img`
    height: 40px;
`;

const TopDialogImagePlaceholder = styled.div`
    height: 200px;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});
    background-color: rgba(211,211,211,0.05);
    margin-bottom: 10px;
    border-radius: 4px;
`;

const Wrapper = styled.div`
    position: relative;
`;

const MoLabel = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
`;

const MoVal = styled.div`
    //color: white;
    color: black;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
`;

const ProofVal = styled(MoVal)`
    font-size: 12px;
    text-overflow: unset;
    white-space: unset;
    overflow-x: auto;
    word-break: break-all;
`;

const MoRow = styled.div`
    margin-bottom: 20px;
`;

const SelNamePlaceholder = styled.div`
    font-weight: bold;
    //color: white;
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
`;

const MoLink = styled.a`
    //color: white;
    color: black;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const CloudImage = styled.img`
    margin-bottom: 10px;
    height: 78px;
`;

const UploadText = styled.div`
    text-align: center;
    color: #FFAC30;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
`;

const StatsPlaceholder = styled.div`
    font-size: 12px;
    color: white;
    opacity: 0.5;
`;


const UploadingModal = styled.div`
    position: absolute;
    top: 0px; 
    left: 0px;
    right: 0px;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #181923;
`;


const UploadingPercentsPlaceholder = styled.div`
    text-align: center;
    color: white;
    align-items: center;
    letter-spacing: -1.25px;
    font-size: 64px;
    line-height: 48px;
    font-weight: bold;
`;

const UploadingText = styled.div`
    margin-top: 40px;
    text-align: center;
    color: white;
`;

const UploadingMessage = styled.div`
    
`;

const Content = styled.div`
    margin-top: 40px;
`;

const InlineBox = styled.div`
    width: calc((100% - 40px) / 3);
    box-sizing: border-box;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    :nth-of-type(3n){
      margin-right: 0px;
    }
    :nth-of-type(3n + 1){
      margin-left: 0px;
    }
`;

const LoaderPlaceholder = styled.div`
    position: absolute;
    //top: 20px;
    //right: 20px;
    top: 45px;
    right: 30px;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
`;

const TopName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    color: #465E6F;
`;

const TopRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
