import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UniTemplate from "../templates/UniTemplate";

import SelfLoadingFaqPanel from '../faq/panels/SelfLoadingFaqPanel'

export default function KnowledgeApp(props) {
    let {id} = props.match.params;

    return (
        <UniTemplate active={`knowledge`}>

            <Wrapper>
                {/*{'Вы можете ознакомиться с '}*/}
                {/*<a href={'https://docs.google.com/document/d/1_7YsbUQSOTUt00XggbCq0HM7DGLG-2JIvRONUWyxbe0/edit'}*/}
                    {/*target={'_blank'}*/}
                {/*>*/}
                    {/*инструкцией пользователя*/}
                {/*</a>*/}
                {/*.*/}

                <SelfLoadingFaqPanel/>

            </Wrapper>


        </UniTemplate>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 40px;
    color: black;
    @media(max-width: 1200px){
      width: 100%;
    }
`;
