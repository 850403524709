import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import UserSettingsPanel from "../settings/panels/UserSettingsPanel";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserProfilePanel from "../profile/panels/UserProfilePanel";
import UniTemplate from "../templates/UniTemplate";

export default function UserProfileApp() {

    return (
        <UniTemplate active={'profile'}>

            <Wrapper>
                <UserProfilePanel/>
            </Wrapper>

        </UniTemplate>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 40px;
    color: black;
    @media(max-width: 1200px){
      width: 100%;
    }
`;
