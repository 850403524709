import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'

import NiceModal from '../../modals/NiceModal'

const ie = x => (x == undefined || x.trim() == '');

export default function StyledSignupForm(props) {
    const {
        onSubmit = () => {

        },
        onLoginClick = () => {
        },
        loading = false
    } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');

    const [message, setMessage] = useState(undefined);

    const [checked, setChecked] = useState(false);

    let canSubmit = true;
    if (checked == false || (password != confirmation) || password.length < 3 || validateEmail(email) == false) {
        canSubmit = false;
    }
    if (ie(firstName) || ie(lastName) || ie(phone)) {
        canSubmit = false;
    }


    return (
        <Wrapper>

            <TopPlaceholder>
                Регистрация
            </TopPlaceholder>

            <SubTopPlaceholder>
                Создайте аккаунт, чтобы продолжить
            </SubTopPlaceholder>

            <ContentPlaceholder>

                <Field>
                    <Label>
                        Имя
                    </Label>
                    <Input

                        autoComplete={false}
                        autoFill={false}
                        autoFocus={true}
                        value={firstName} placeholder={'Иван'} onChange={e => {
                        setFirstName(e.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Фамилия
                    </Label>
                    <Input
                        autoComplete={false}
                        autoFill={false}
                        value={lastName} placeholder={'Петров'} onChange={e => {
                        setLastName(e.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        E-mail
                    </Label>
                    <Input
                        autoComplete={false}
                        autoFill={false}
                        value={email} type={'email'} placeholder={'ivan.petrov@yandex.ru'} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Пароль
                    </Label>
                    <Input value={password} type={'password'} placeholder={''}
                           onKeyPress={e => {

                           }}
                           onChange={e => {
                               setPassword(e.target.value);
                           }}
                           autoComplete={false}
                           autoFill={false}
                    />
                </Field>

                <Field>
                    <Label>
                        Повторите пароль
                    </Label>
                    <Input value={confirmation} type={'password'} placeholder={''}
                           onKeyPress={e => {

                           }}
                           onChange={e => {
                               setConfirmation(e.target.value);
                           }}
                           autoComplete={false}
                           autoFill={false}
                    />
                </Field>

                <Field>
                    <Label>
                        Телефон
                    </Label>
                    <Input
                        autoComplete={false}
                        autoFill={false}
                        value={phone} type={'phone'} placeholder={'+7 000 000 0000'} onChange={e => {
                        setPhone(e.target.value);
                    }}/>
                </Field>

                <BottomField style={{marginBottom: 40}}>
                    <BottomLeftCheckboxPlaceholder onClick={() => {
                        setChecked(!checked);
                    }}>
                        {checked == false ? null :
                            <img style={{width: '100%'}} src={require('../../../assets/images/exp_check.svg')}/>
                        }
                    </BottomLeftCheckboxPlaceholder>
                    <BottomAcceptTextPlaceholder style={{flex: 1}}>
                        {'Я принимаю условия '}
                        <NiceLink href={'https://patent.tsu.ru/patent_tsu_agreement.pdf'} target={'_bkank'}>
                            {'пользовательского соглашения'}
                        </NiceLink>
                        {' и даю согласие на '}
                        <NiceLink href={'https://patent.tsu.ru/patent_tsu_pers_agreement.pdf'} target={'_blank'}>
                            {'обработку персональных данных'}
                        </NiceLink>
                    </BottomAcceptTextPlaceholder>
                </BottomField>

                <Field>
                    <Button
                        disabled={!checked}
                        onClick={() => {
                            if (checked == false) {
                                return;
                            }
                            if (ie(firstName) == true) {
                                setMessage('Введите имя');
                                return;
                            }
                            if (ie(lastName) == true) {
                                setMessage('Введите фамилию');
                                return;
                            }
                            if (ie(phone) == true) {
                                setMessage('Введите номер телефона');
                                return;
                            }
                            if (password != confirmation || password.length < 5) {
                                setMessage('Некорректный пароль. Или подтверждение пароля не совпадает с паролем');
                                return;
                            }
                            if (validateEmail(email) == false) {
                                setMessage('Некорректный email');
                                return;
                            }

                            onSubmit({email: email.toLowerCase(), password, firstName, lastName, phone})
                        }}>
                        <Spin theme={'alfa-on-color'} visible={loading}/>
                        {loading == true ? null :
                            'Зарегистрироваться'
                        }
                    </Button>
                </Field>
            </ContentPlaceholder>

            <BottomPlaceholder>
                <span style={{opacity: 0.65}}>
                    У вас есть учетная запись?
                </span>
                <SignupSpan style={{marginLeft: 16}} onClick={() => {
                    onLoginClick();
                }}>
                    Войти
                </SignupSpan>
            </BottomPlaceholder>

            {message == undefined ? null :
                <NiceModal
                    width={360} subHeading={''} onClose={() => {
                    setMessage(undefined);
                }}>
                    <div style={{textAlign: 'center', padding: 20, boxSizing: 'border-box'}}>
                        {message}
                    </div>
                </NiceModal>
            }

        </Wrapper>
    );
}

const NiceLink = styled.a`
    color: #0072BC;
    opacity: 0.8;
    text-decoration: underline;
    :hover{
      opacity: 1;
    }
    :visited{
      color: #0072BC;
    }
`;

const BottomField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const BottomLeftCheckboxPlaceholder = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgrey;
    margin-right: 20px;
    cursor: pointer;
`;

const BottomAcceptTextPlaceholder = styled.div`
    
`;

const PrivacyPlaceholder = styled.div`
    
`;

const SignupSpan = styled.span`
    cursor: pointer;
    color: #0072BC;
    letter-spacing: -0.0642857px;
    text-decoration-line: underline;
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
    margin-left: 16px;
    opacity: 0.8;
    :hover{
      opacity: 1;
    }
`;


const ForgotPlaceholder = styled.div`
    text-align: right;
    color: #00A3AB;
`;

const ForgotSpan = styled.span`
    cursor: pointer;
    opacity: 0.8;
    :hover{
      opacity: 1;
    }
`;

const BottomPlaceholder = styled.div`
    color: #202224;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
`;


const Wrapper = styled.div`

`;

const SubTopPlaceholder = styled.div`
    color: #202224;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.0642857px;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 25px;
`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: #465E6F;
    text-align: center;
`;

const ContentPlaceholder = styled.div`
    
`;


const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 5px;
    opacity: 0.8;
    letter-spacing: -0.0642857px;
    
    color: #202224;
`;


const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;

function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
