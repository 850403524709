import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import CommonHelper from "../../../helpers/CommonHelper";

import ls from 'local-storage'

const haImg = require('../../../assets/images/circle_ha.svg')

const ITEMS = [
    'Загрузка объекта ИС',
    'Проверка хэш-записей файлов загруженного и эталонного',
    'Выдача приложения к свидетельству о депонировании'
];

const PRICING_ITEMS = [
    {
        price: '2 000 ₽',
        size: '50',
        m: 'Мб'
    },
    {
        price: '3 500 ₽',
        size: '100',
        m: 'Мб'
    },
    {
        price: '9 000 ₽',
        size: '500',
        m: 'Мб'
    },
    {
        price: '15 000 ₽',
        size: '1',
        m: 'Гб'
    }
];


export default function PlansSection(props) {
    const {} = props;
    const [selectedPriceNumber, setSelectedPriceNumber] = useState(1);

    return (
        <Wrapper>

            <Heading>
                Тарифы
            </Heading>

            <TripletsPlaceholder>
                <TripletItem>
                    <H1>
                        Цифровое свидетельство
                    </H1>
                    <PricePlaceholder>
                        <BoldSpan>
                            0<sup>*</sup>
                        </BoldSpan>
                        <DimSpan>
                            ₽/ед
                        </DimSpan>
                        <br/>
                        <BoldSpan>
                            1000
                        </BoldSpan>
                        <DimSpan>
                            ₽/ед
                        </DimSpan>
                    </PricePlaceholder>

                    <H1>
                        Бумажное свидетельство
                    </H1>

                    <PricePlaceholder>
                        <BoldSpan>
                            500<sup>*</sup>
                        </BoldSpan>
                        <DimSpan>
                            ₽/ед
                        </DimSpan>
                        <br/>
                        <BoldSpan>
                            1500
                        </BoldSpan>
                        <DimSpan>
                            ₽/ед
                        </DimSpan>
                    </PricePlaceholder>

                    <DescPlaceholder>
                        <sup>
                            *
                        </sup>
                        Тариф действует для сотрудников НИ ТГУ, обучающихся лиц, участников научно-исследовательских и
                        образовательных программ.
                    </DescPlaceholder>

                </TripletItem>


                <TripletItem style={{display: 'inline-flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div>
                        <H1>
                            Емкость хранилища
                        </H1>
                        <PricePlaceholder>

                            {PRICING_ITEMS.map((a, i) => {
                                let {size, m, price} = a;
                                let isSelected = (i == selectedPriceNumber);
                                return (
                                    <PriceRow onClick={() => {
                                        setSelectedPriceNumber(i);
                                    }}>
                                        <PriceRowLeft>
                                            <LeftCircle>
                                                {isSelected && <LeftCircleHeart/>}
                                            </LeftCircle>
                                            <span style={{marginLeft: 5, color: '#0072BC', fontWeight: 'bold', fontSize: 36}}>
                                                {`${size} `}
                                            </span>
                                            <span style={{marginLeft: 5, fontSize: 16}}>
                                                {m}
                                            </span>
                                        </PriceRowLeft>
                                        <PriceRowRight>
                                            {price}
                                        </PriceRowRight>
                                    </PriceRow>
                                )
                            })}

                        </PricePlaceholder>

                        <DescPlaceholder style={{marginBottom: 40}}>
                            <sup>
                                *
                            </sup>
                            Срок хранения 5 лет
                        </DescPlaceholder>
                    </div>

                    <ActionButton onClick={() => {
                        ls('login_mode', 'signup');
                        CommonHelper.linkTo('/login');
                    }}>
                        Выбрать тариф
                    </ActionButton>

                </TripletItem>

                <TripletItem selected={true}
                             style={{display: 'inline-flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div>
                        <H1 style={{color: 'white'}}>
                            Верификация эталона
                        </H1>

                        <div style={{fontSize: 32, color: 'white'}}>
                        <span style={{fontWeight: 'bold'}}>
                            2 000
                        </span>
                            <span style={{marginLeft: 7, fontSize: 24}}>
                            ₽/ед
                        </span>
                        </div>

                        <IncludesPlaceholder>

                            <div style={{fontSize: 12, color: 'white', marginBottom: 20}}>
                                Включает:
                            </div>

                            {ITEMS.map((it, j) => {
                                return (
                                    <IncludesRow key={j}>
                                        <CheckImg src={require('../../../assets/images/ddd_qw.svg')}/>
                                        <InclSpan>
                                            {it}
                                        </InclSpan>
                                    </IncludesRow>
                                )
                            })}

                        </IncludesPlaceholder>
                    </div>

                    <ActionButton onClick={() => {
                        ls('login_mode', 'signup');
                        CommonHelper.linkTo('/login');
                    }}>
                        Попробовать сейчас
                    </ActionButton>

                </TripletItem>

            </TripletsPlaceholder>

        </Wrapper>
    );
}

const PriceRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const LeftCircle = styled.div`
    border-radius: 1000px;
    border: 1px solid rgba(70, 94, 111, 0.5);
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    :hover{
      opacity: ${props => (props.selected == true ? 1 : 0.9)};
    }
`;

const LeftCircleHeart = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 1000px;
    background: #0072BC;
`;

const PriceRowLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const PriceRowRight = styled.div`
    color: #465E6F;
    font-size: 24px;
    font-weight: bold;
`;

const DimSpan = styled.span`
    color: #465E6F;
    font-size: 20px;
    //font-weight: bold;
    margin-left: 5px;
`;

const BoldSpan = styled.span`
    font-weight: bold;
    color: #147DC1;
`;

const IncludesPlaceholder = styled.div`
    color: white;
    margin-bottom: 40px;
    line-height: 20px;
    margin-top: 20px;
    font-weight: bold;
`;

const IncludesRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`;

const CheckImg = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 20px;
`;

const InclSpan = styled.div`
    color: white;
`;

const DescPlaceholder = styled.div`
    color: #0A1B32;
    opacity: 0.5;
`;

const H1 = styled.div`
    color: #0A1B32;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
`;

const PricePlaceholder = styled.div`
    color: #147DC1;
    font-size: 36px;
    line-height: 70px;
    margin-bottom: 30px;
`;

const Wrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
`;


const ActionButton = styled.div`
    padding-left: 50px;
    padding-right: 50px;
    color: #0072BC;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: bold;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 40px;
    :hover{
      opacity: 0.8;
    }
`;

const HaCircle = styled.div`
    width: 70px;
    height: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${haImg});
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 40px;
`;

const ItemHeading = styled.div`
    font-size: 24px;
    color: #465E6F;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ItemDescription = styled.div`
    color: #3E4462;
    font-size: 16px;
    line-height: 28px;
`;

const CirclePlaceholder = styled.div`
    margin-bottom: 20px;
`;

const Heading = styled.div`
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    font-size: 36px;
    color: #465E6F;
`;

const TripletsPlaceholder = styled.div`
    
`;

const TripletItem = styled.div`
    margin: 20px;
    display: inline-block;
    vertical-align: top;
    width: calc(33.33% - 40px);
    background: ${props => (props.selected == true ? '#147DC1' : '#FFFFFF')};
    box-shadow: 0px 20px 48px rgba(7, 84, 134, 0.05);
    padding: 32px;
    box-sizing: border-box;
    border-radius: 20px;
    min-height: 620px;
    @media(max-width: 720px){
      width: 100%;
      display: block;
      box-sizing: border-box;
      min-width: auto;
      margin: 0px;
      margin-bottom: 10px;
    }
`;

