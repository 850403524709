import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

// import {UPLOAD_DO_ENDPOINT_URL} from "../../constants/config";

import Dropzone, {useDropzone} from 'react-dropzone'
import axios from 'axios'
import {API_ENDPOINT} from "../../constants/config";

export default function SimpleUploadPanel(props) {
    const {
        uploadSuffix = 'v1/upload',
        onUploaded = pld => {
        },
        onStart = () => {

        },
        onFail = () => {

        },
        onProgress = d => {

        },
        children,
        // accept = '.mp4,.mov,.mpg,.vob,.avi'
        accept = '.mp4,.mov,.mpg,.vob,.avi,.doc,.docx,.jpg,.png,.mp3,.bmp,.wav,.pdf'
    } = props;

    const onDrop = useCallback((files) => {
        console.log('onDrop occured: files = ', files);
        let file = files[0];
        const fd = new FormData();
        fd.append('file', file);
        onStart();
        axios.post(`${API_ENDPOINT}/${uploadSuffix}`, fd, {
            onUploadProgress: progressEvent => {
                let {loaded, total} = progressEvent;
                let progress = 100.0 * (+loaded) / (+total);
                console.log('progress: ', progress);
                onProgress({progress, loaded, total});
            }
        }).then(d => d.data.result).then(pld => {
            onUploaded(pld);
        }).catch(err => {
            onFail(err);
        })
    }, []);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});


    return (
        <Wrapper className={'SimpleUploadPanel_wr'} {...getRootProps()} >
            <input {...getInputProps()} accept={accept} />
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px; 
    outline: none;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
