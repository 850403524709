import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import UserSettingsPanel from "../settings/panels/UserSettingsPanel";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";

export default function UserSettingsApp() {

    return (
        <SubtitlesTemplate active={'settings'}>

            <UserSettingsPanel/>

        </SubtitlesTemplate>
    );
}
