import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleUploadPanel from "./SimpleUploadPanel";

const getMb = a => {
    if (a == undefined || window.isNaN(a) == true) {
        return 0;
    }
    return +(+a / 1024.0 / 1024.0).toFixed(1);
}

export default function UploadVideoPanel(props) {
    const {
        uploadText = 'Защитить новый объект',
        onUploaded = u => {

        },
        onProgress = d => {

        },
        onStart = () => {

        }
    } = props;
    const [uploading, setUploading] = useState(false);
    const [url, setUrl] = useState();
    const [progressData, setProgressData] = useState(undefined);
    let text = (uploading == true) ? `Uploading...` : uploadText;
    if (url != undefined) {
        text = 'Uploaded!';
    }
    let statsText = (progressData == undefined) ? '' : `${progressData.progress.toFixed(0)}% (${getMb(progressData.loaded)} MB)`;

    return (
        <Wrapper>
            <SimpleUploadPanel onUploaded={pld => {
                setUrl(pld.url);
                setUploading(false);
                onUploaded(pld);
            }} onProgress={d => {
                console.log('onProgress: d = ', d);
                onProgress(d);
                setProgressData(d);
            }} onStart={() => {
                setUploading(true);
                onStart();
            }}>
                <Inner>
                    <CloudImage src={require('../../assets/images/mdi_cloud_upload.svg')}/>
                    <UploadText>
                        {text}
                    </UploadText>
                    {uploading == false ? null :
                        <StatsPlaceholder>
                            {statsText}
                        </StatsPlaceholder>
                    }
                </Inner>
            </SimpleUploadPanel>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;  
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
`;

const CloudImage = styled.img`
    margin-bottom: 10px;
`;

const UploadText = styled.div`
    text-align: center;
    color: #FFAC30;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
`;

const StatsPlaceholder = styled.div`
    font-size: 12px;
    color: white;
    opacity: 0.5;
`;
