import RidsAPI from '../../api/RidsAPI'

import * as types from '../ActionTypes'


const loadRids_ = () => {
    return {
        type: types.LOAD_RIDS
    }
}
export const loadRidsSuccess = (rids) => {
    return {
        type: types.LOAD_RIDS_SUCCESS,
        rids: rids
    }
}
let loadRidsFailed = (error) => {
    return {
        type: types.LOAD_RIDS_FAIL,
        error: error
    }
}

//thunk
export function loadAllRids() {
    return (dispatch, getState) => {
        dispatch(loadRids_());
        return RidsAPI.getAllRids().then(
            rids => dispatch(loadRidsSuccess(rids)),
            error => dispatch(loadRidsFailed(error))
        )
    }
}

export function loadUserRids(userId) {
    return (dispatch, getState) => {
        dispatch(loadRids_());
        return RidsAPI.getUserRids(userId).then(
            rids => dispatch(loadRidsSuccess(rids)),
            error => dispatch(loadRidsFailed(error))
        )
    }
}

export function getRid(id) {
    return (dispatch, getState) => {
        dispatch(loadRids_());
        return RidsAPI.getRid(id).then(
            proj => dispatch(loadRidsSuccess([proj])),
            error => dispatch(loadRidsFailed(error))
        )
    }
}

let createRid_ = () => {
    return {
        type: types.CREATE_RID
    }
}
let createRidSuccess = (rid) => {
    return {
        type: types.CREATE_RID_SUCCESS,
        rid: rid
    }
}
let createRidFailed = (error) => {
    return {
        type: types.CREATE_RID_FAIL,
        error: error
    }
}

//thunk
export function createRid(data) {
    return (dispatch, getState) => {
        dispatch(createRid_());
        return RidsAPI.createRid(data).then(
            rid => dispatch(createRidSuccess(rid)),
            error => dispatch(createRidFailed(error))
        )
    }
}

let updateRid_ = () => {
    return {
        type: types.UPDATE_RID
    }
}
let updateRidSuccess = (rid) => {
    return {
        type: types.UPDATE_RID_SUCCESS,
        rid: rid
    }
}
let updateRidFailed = (error) => {
    return {
        type: types.UPDATE_RID_FAIL,
        error: error
    }
}

//thunk
export function updateRid(data) {
    return (dispatch, getState) => {
        dispatch(updateRid_());
        return RidsAPI.updateRid(data).then(
            rid => dispatch(updateRidSuccess(rid)),
            error => dispatch(updateRidFailed(error))
        )
    }
}


let deleteRid_ = () => {
    return {
        type: types.DELETE_RID
    }
}
let deleteRidSuccess = (id) => {
    return {
        type: types.DELETE_RID_SUCCESS,
        id: id
    }
}
let deleteRidFailed = (error) => {
    return {
        type: types.DELETE_RID_FAIL,
        error: error
    }
}

//thunk
export function deleteRid(id) {
    return (dispatch, getState) => {
        dispatch(deleteRid_());
        return RidsAPI.deleteRid(id).then(
            () => dispatch(deleteRidSuccess(id)),
            error => dispatch(deleteRidFailed(error))
        )
    }
}
