import styled from 'styled-components';
import {BROWN, DARK_SIDE, LIGHT_BROWN, MAIN_ORANGE_COLOR} from "./Colors";

const DEFAULT_BACKGROUND = LIGHT_BROWN;


const DEFAULT_INPUT_HEIGHT = 54;
const DEFAULT_INPUT_FONT_SIZE = 18;

export const Input = styled.input`
    width: 100%;
    box-sizing: border-box;
    height: ${DEFAULT_INPUT_HEIGHT}px;
    line-height: ${DEFAULT_INPUT_HEIGHT}px;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    padding-left: 3px;
    font-size: ${DEFAULT_INPUT_FONT_SIZE}px;
    color: #465E6F;
    
    :focus{
      border-color: #465E6F;
    }
`;
