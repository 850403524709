import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../helpers/CommonHelper";

import * as usersActions from '../../redux/actions/UsersActions'
import FooterSection from "./FooterSection";

export default function SubtitlesTemplate(props) {
    let {active} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = (currentUser != undefined && currentUser.userRole == 'admin');
        return {
            currentUser: currentUser,
            loading: state.users.loading,
            isAdmin: isAdmin
        }
    }, []));

    let namItems = (isAdmin == true) ? ADMIN_NAV_ITEMS : NAV_ITEMS;

    console.log('SubtitlesTemplate: isAdmin = ', isAdmin);

    return (
        <Wrapper>

            <Container>
                <TopPlaceholder>
                    <LogoPlaceholder>
                        <Logo src={require('../../assets/images/law_logo.png')}/>
                    </LogoPlaceholder>
                    <TopRightPlaceholder>
                        <NavItemsPlaceholder>
                            {namItems.map((a, k) => {
                                return (
                                    <NavItem key={k} onClick={() => {
                                        CommonHelper.linkTo(a.url);
                                    }}>
                                        {a.label}
                                    </NavItem>
                                )
                            })}

                            {/*<CurrentUserItem onClick={() => {*/}
                            {/*CommonHelper.linkTo('/profile');*/}
                            {/*}}>*/}
                            {/*/!*<UserAvatar*!/*/}
                            {/*/!*src={(currentUser == undefined || currentUser.avatar == undefined) ? require('../../assets/images/void_ava.jpg') : currentUser.avatar}/>*!/*/}
                            {/*<CurrentUserInfo>*/}
                            {/*<CurrentUserName>*/}
                            {/*{CommonHelper.getUserName(currentUser)}*/}
                            {/*</CurrentUserName>*/}
                            {/*<MyProfilePlaceholder>*/}
                            {/*My profile*/}
                            {/*</MyProfilePlaceholder>*/}
                            {/*</CurrentUserInfo>*/}
                            {/*</CurrentUserItem>*/}

                        </NavItemsPlaceholder>
                    </TopRightPlaceholder>
                </TopPlaceholder>

                <MainPlaceholder>
                    {props.children}
                </MainPlaceholder>

                {/*<FooterPlaceholder>*/}
                {/*<FooterSection onLogout={async () => {*/}
                {/*await dispatch(usersActions.logOut());*/}
                {/*window.location.reload();*/}
                {/*}}/>*/}
                {/*</FooterPlaceholder>*/}

            </Container>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const CurrentUserItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const CurrentUserName = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
`;

const MyProfilePlaceholder = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #EFEFEF;
`;

const CurrentUserInfo = styled.div`
    margin-left: 5px;
`;

const UserAvatar = styled.div`
    border-radius: 1000px;
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("${props => props.src}");
`;

const Container = styled.div`
    width: 1020px;
    margin: 0 auto;
    padding-top: 20px;
    @media(max-width: 1020px){
      width: 100%;
    }
`;

const MainPlaceholder = styled.div`
    min-height: calc(100vh - 480px);
    padding-top: 20px;
`;

const FooterPlaceholder = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const LogoPlaceholder = styled.div`
    
`;

const TopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const NavItemsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const NavItem = styled.div`
    margin-right: 30px;
    color: black;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const Logo = styled.img`
    height: 95px;
`;

const NAV_ITEMS = [
    {
        label: 'Мои файлы',
        name: 'index',
        url: '/'
    },
    // {
    //     label: 'Pricing',
    //     name: 'pricing',
    //     url: '/pricing'
    // },
    {
        label: 'Настройки',
        name: 'settings',
        url: '/settings'
    },
    {
        label: 'Помощь',
        name: 'help',
        url: '/help'
    },
];

const ADMIN_NAV_ITEMS = [
    {
        label: 'Главная',
        name: 'index',
        url: '/',
        icon: require('../../assets/images/home-icon-silhouette.svg')
    },
    {
        label: 'Пользователи',
        name: 'users',
        url: '/users',
        icon: require('../../assets/images/group-d.svg')
    },
    {
        label: 'Настройка типов ридов',
        name: 'rids',
        url: '/rids',
        icon: require('../../assets/images/group-d.svg')
    },
    {
        label: 'FAQ',
        name: 'faq',
        url: '/faq',
        icon: require('../../assets/images/group-d.svg')
    },
    {
        label: 'Настройки',
        name: 'settings',
        url: '/settings'
    }
    // {
    //     label: 'Subscriptions',
    //     name: 'subscriptions',
    //     url: '/subscriptions',
    //     icon: require('../../assets/images/subscription_white.svg')
    // },
    // {
    //     label: 'Servers',
    //     name: 'servers',
    //     url: '/servers',
    //     icon: require('../../assets/images/subscription_white.svg')
    // },
    // {
    //     label: 'Stats',
    //     name: 'stats',
    //     url: '/stats',
    //     icon: require('../../assets/images/bar-chart.svg')
    // },
];
