import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    ridsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const RidsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_RID:
        case types.UPDATE_RID:
        case types.DELETE_RID:
        case types.LOAD_RIDS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_RID_FAIL:
        case types.UPDATE_RID_FAIL:
        case types.DELETE_RID_FAIL:
        case types.LOAD_RIDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_RID_SUCCESS:
        case types.UPDATE_RID_SUCCESS:
            return {
                ...state,
                loading: false,
                ridsMap: state.ridsMap.set(action.rid.id, action.rid)
            }


        case types.DELETE_RID_SUCCESS:
            return {
                ...state,
                loading: false,
                ridsMap: state.ridsMap.delete(action.id),
            }


        case types.LOAD_RIDS_SUCCESS:
            return {
                ...state,
                loading: false,
                ridsMap: state.ridsMap
                    .merge(action.rids.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default RidsReducer;
