import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import * as actions from "../../../redux/actions/UsersActions";

import Sidebar from 'arui-feather/sidebar'
import {Button} from '../../ui/Button'
import CommonHelper from "../../../helpers/CommonHelper";
import Tabs, {TabItem} from "../../ui/Tabs";
import UserWorkspacePanel from "../../workspace/panels/UserWorkspacePanel";

const renderUsersList = (users, onUserClick, selectedId) => {
    return (
        <div>
            {users.map((user, k) => {
                let {avatar, attachments, email, firstName, lastName} = user;
                let isSelected = (selectedId == user.id);
                return (
                    <DocumentItem selected={isSelected} key={user.id}>
                        <DocumentInner selected={isSelected} onClick={() => {
                            onUserClick(user.id);
                        }}>
                            <DocumentContent>
                                <Name>
                                    {`${CommonHelper.getUserName(user)}`}
                                </Name>
                            </DocumentContent>
                        </DocumentInner>
                    </DocumentItem>
                )
            })}
        </div>
    )
}

export default function AdminAllUsersPanel(props) {
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const {loading, users, currentUser, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin'));
        return {
            loading: state.users.loading,
            users: state.users.usersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllUsers());
    }, []);
    let selectedUser = (selectedId == undefined) ? undefined : users.filter(d => (d.id == selectedId))[0];

    return (
        <Wrapper>

            <DocumentsList>
                {renderUsersList(users, uId => {
                    setSelectedId(uId);
                }, selectedId)}
            </DocumentsList>


            <Sidebar width={Math.min(window.innerWidth, 1020)} visible={(selectedUser != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedUser == undefined ? null :
                    <div>
                        <h3>
                            {`${CommonHelper.getUserName(selectedUser)}`}
                        </h3>
                        {/*{JSON.stringify(selectedUser)}*/}
                        <UserWorkspacePanel userId={selectedUser.id}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const DocumentsList = styled.div`
    
`;

const DocumentItem = styled.div`
    box-sizing: border-box;
    padding: 10px;
`;

const DocumentInner = styled.div`
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const DocumentContent = styled.div`
    padding: 5px;
    box-sizing: border-box;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
`;

const Description = styled.div`
    
`;

const DocumentAvatar = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.src});
    background-color: whitesmoke;
    width: 100%;
    height: 220px;
`;

const BottomPlaceholder = styled.div`
    margin-top: 10px;
`;
