import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {API_ENDPOINT} from "../../constants/config";

import * as usersActions from '../../redux/actions/UsersActions'
import {useDispatch, useMappedState} from "redux-react-hook";
import StyledLoginForm from "../auth/forms/StyledLoginForm";
import RecoverPasswordForm from "../auth/forms/RecoverPasswordForm";
import StyledSignupForm from "../auth/forms/StyledSignupForm";

import ls from 'local-storage'

import axios from 'axios'
import FittedVideoPanel from "../video/FittedVideoPanel";
import CommonHelper from "../../helpers/CommonHelper";
import NiceModalWithHeading from "../modals/NiceModalWithHeading";
import NiceModal from "../modals/NiceModal";

export default function BrandedLoginApp(props) {

    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            loading: state.users.loading,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []);
    const {initialized, currentUser, loading} = useMappedState(mapState);
    const dispatch = useDispatch();
    const [mode, setMode] = useState(ls('login_mode') == undefined ? 'login' : ls('login_mode'));
    const [recovering, setRecovering] = useState(false);
    const [registering, setRegistering] = useState(false);

    const [message, setMessage] = useState(undefined);

    useEffect(() => {
        ls('login_mode', mode);
    }, [mode]);

    return (
        <Wrapper>

            <LoginInnerPanel>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 60}}>
                    <LogoTopPlaceholder onClick={() => {
                        CommonHelper.linkTo('/');
                    }}>
                        <Logo src={require('../../assets/images/pat_tsu_logo.svg')}/>
                        <LogoText>
                            <LogoTextTop>
                                PATENT TSU
                            </LogoTextTop>
                            <LogoTextBottom>
                                регистратор интеллектулальной собтственности
                            </LogoTextBottom>
                        </LogoText>
                    </LogoTopPlaceholder>
                </div>


                <Inner>

                    {mode != 'login' ? null :
                        <StyledLoginForm loading={loading} onSubmit={data => {
                            dispatch(usersActions.logIn(data)).then(pld => {
                                console.log('pld = ', pld);
                                if (pld.error != undefined) {
                                    // window.alert(pld.error.message);
                                    setMessage('Неверная пара почта/пароль')
                                }
                            })
                        }}
                                         onLostPasswordClick={() => {
                                             setMode('lost_password');
                                         }}
                                         onSignUpClick={() => {
                                             setMode('signup');
                                         }}
                        />
                    }

                    {mode != 'signup' ? null :
                        <StyledSignupForm
                            loading={registering}
                            onLostPasswordClick={() => {
                                setMode('lost_password');
                            }}

                            onSignUpClick={() => {
                                setMode('signup');
                            }}
                            onLoginClick={() => {
                                setMode('login');
                            }}
                            onSubmit={data => {
                                setRegistering(true);
                                axios.post(`${API_ENDPOINT}/users/create`, data).then(d => d.data).then(pld => {
                                    if (pld.error != undefined) {
                                        // window.alert(pld.error.message);
                                        setMessage('Некорректно введены данные при регистрации. Возможно, данный пользователь уже зарегистрирован в системе.')
                                        setRegistering(false);
                                        return;
                                    }
                                    setRegistering(false);
                                    dispatch(usersActions.logIn(data)).then(pld => {
                                        console.log('signing in');
                                    });
                                })
                            }}
                        />
                    }

                    {mode != 'lost_password' ? null :

                        <RecoverPasswordForm
                            loading={recovering}
                            onLostPasswordClick={() => {
                                setMode('lost_password');
                            }}
                            onSignUpClick={() => {
                                setMode('signup');
                            }}
                            onLoginClick={() => {
                                setMode('login');
                            }}
                            onSubmit={email => {
                                setRecovering(true);
                                axios.post(`${API_ENDPOINT}/recover`, {email: email}).then(d => d.data).then(pld => {
                                    console.log('pld = ', pld);
                                    if (pld.error != undefined) {
                                        setRecovering(false);
                                        // window.alert(pld.error.message);
                                        setMessage('Данный почтовый адрес не найден в системе.')
                                        return;
                                    }
                                    window.alert('Please check your inbox');
                                    setRecovering(false);
                                })
                            }}
                        />

                    }

                </Inner>

            </LoginInnerPanel>

            {message == undefined ? null :
                <NiceModal
                    width={360} subHeading={''} onClose={() => {
                    setMessage(undefined);
                }}>
                    <div style={{textAlign: 'center', padding: 20, boxSizing: 'border-box'}}>
                        {message}
                    </div>
                </NiceModal>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: #E5E5E5;
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
`;

const LeftBg = styled.img`
    position: fixed;
    left: 0px;
    bottom: 0px;
    top: 0px;
`;

const RightBg = styled.img`
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
`;

const LogoTopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Logo = styled.img`
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
`;

const LogoText = styled.div`
    cursor: pointer;
`;

const LogoTextTop = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #0072BC;  
    letter-spacing: 10px;
`;

const LogoTextBottom = styled.div`
    font-size: 10px;
    line-height: 10px;
    color: grey;
    opacity: 0.8;
`;

const LoginInnerPanel = styled.div`
    position: relative;
    background: #F7F8F9;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-top: 5vh;
    padding-bottom: 5vh;
`;

const Inner = styled.div`
    background: #FFFFFF;
    box-shadow: 6px 40px 80px rgba(0, 114, 188, 0.15);
    border-radius: 18px;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    width: 630px;
    margin: 0 auto;
    padding: 35px;
    padding-left: 47px;
    padding-right: 47px;
    z-index: 10000000;
    @media(max-width: 630px){
      width: 100%;
    }
`;

const TopLogo = styled.img`
    margin-bottom: 40px;
    margin-top: 10px;
    cursor: pointer;
    width: 160px;
    :hover{
      opacity: 0.8;
    }
    @media(max-height: 720px){
      display: none;
    }
`;
