import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserWorkspacePanel from "../workspace/panels/UserWorkspacePanel";
import UniTemplate from "../templates/UniTemplate";

export default function IndexApp() {

    return (
        <UniTemplate active={'index'}>

            <Wrapper>
                <UserWorkspacePanel/>
            </Wrapper>

        </UniTemplate>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 40px;
    @media(max-width: 1200px){
      width: 100%;
    }
`;
