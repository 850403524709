import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UpdateUserInfoForm from "../../users/forms/UpdateUserInfoForm";

import * as actions from '../../../redux/actions/UsersActions'

export default function UserSettingsPanel(props) {
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [removing, setRemoving] = useState(false);
    const {user, loading} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            loading: state.users.loading,
            user: currentUser,
        }
    }, []));
    const dispatch = useDispatch();
    if (user == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopName>
                    Настройки
                </TopName>
                <TopRight>
                    <span style={{color: 'white', cursor: 'pointer'}} onClick={async () => {
                        await dispatch(actions.logOut());
                    }}>
                        выход
                    </span>
                </TopRight>
            </TopPlaceholder>

            <FormPlaceholder>
                <UpdateUserInfoForm
                    {...user}
                    loading={loading} onSave={async d => {
                    await dispatch(actions.updateUser({id: user.id, ...d}))
                }}/>
            </FormPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: #FFFFFF;
`;

const TopRight = styled.div`
    
`;


const FormPlaceholder = styled.div`
    
`;
