import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ProjectsAPI from "../../../api/ProjectsAPI";

import QRCode from 'react-qr-code';
import CommonHelper from "../../../helpers/CommonHelper";
import NiceModal from "../../modals/NiceModal";

export default function VerificationTool(props) {
    const {
        isMini = false
    } = props;

    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState();
    const [sent, setSent] = useState(false);

    const [error, setError] = useState(undefined);

    let errorMessage = (sent == true && project == undefined) ? 'Не найден' : undefined;

    return (
        <Wrapper>

            {project != undefined ? null :
                <React.Fragment>
                    <Heading mini={isMini}>
                        Введите номер свидетельства
                    </Heading>

                    <SubHeading mini={isMini}>
                        Вы сможете увидеть полную информацию о Свидетельстве, если объект задепонирован в реестре
                        patent.tsu.ru
                    </SubHeading>

                    <InputPlaceholder>
                        <Input value={text} placeholder={'*************'} autoFocus={true} onChange={evt => {
                            let ss = evt.target.value.replace(/ /g, '').replace(/\D/g,'');
                            let regex = /[0-9]|\./;
                            if (!regex.test(ss) && ss != '') {
                                return;
                            }
                            if (ss.length > 13) {
                                return;
                            }
                            setText(ss);
                            setProject(undefined);
                            setSent(false);
                        }}/>
                    </InputPlaceholder>

                    {errorMessage == undefined ? null :
                        <ErrorPlaceholder>
                            {errorMessage}
                        </ErrorPlaceholder>
                    }

                    <ButtonPlaceholder>
                        <Button onClick={async () => {
                            if (text.length != 13 || window.isNaN(text) == true) {
                                setError('Некорректно введен номер свидетельства');
                                return;
                            }
                            setLoading(true);
                            setProject(undefined);
                            let p = await ProjectsAPI.getProjectByTimestampOrHash(text);
                            setProject(p);
                            setSent(true);
                            setLoading(false);
                            if (p == undefined) {
                                setError('Файл не найден');
                            }
                        }}>
                            <Spin visible={loading}/>
                            {loading == true ? null :
                                'Проверить файл'
                            }
                        </Button>
                    </ButtonPlaceholder>
                </React.Fragment>
            }

            {error == undefined ? null :
                <NiceModal onClose={() => {
                    setError(undefined);
                }}>
                    <div style={{padding: 20, textAlign: 'center'}}>
                        <div>
                            {error}
                        </div>
                    </div>


                </NiceModal>
            }

            {project == undefined ? null :
                <FoundPlaceholder mini={isMini}>
                    <FoundTop>
                        <img src={require('./images/back_rupt.svg')} style={{height: 54, cursor: 'pointer'}}
                             onClick={() => {
                                 setProject(undefined);
                                 setSent(false);
                             }}/>
                    </FoundTop>

                    <QNamePlaceholder>
                        {`№${project.timestamp}`}
                    </QNamePlaceholder>

                    <DatePlaceholder>
                        {`Создан ${moment(project.timestamp).format('DD.MM.YYYY')}`}
                    </DatePlaceholder>

                    <QrPlaceholder>
                        <QRCode value={`https://patent.tsu.ru/#/cert/${project.id}`} size={160}/>
                    </QrPlaceholder>

                    <ButtonPlaceholder>
                        <Button onClick={async () => {
                            CommonHelper.linkTo(`/cert/${project.id}`)
                        }}>
                            Посмотреть сертификат
                        </Button>
                    </ButtonPlaceholder>
                </FoundPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const FoundPlaceholder = styled.div`
    width: ${props => (props.mini == true ? '100%' : '800px')};
    margin: 0 auto;
`;

const QrPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

const QNamePlaceholder = styled.div`
    color: #465E6F;
    font-size: 48px;
    text-align: center;
`;

const DatePlaceholder = styled.div`
    margin-bottom: 20px;
    text-align: center;
    color: rgba(10, 27, 50, 0.5);
`;

const FoundTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;

const Button = styled.div`
    border-radius: 4px;
    color: white;
    background: #0072BC;
    height: 54px;
    padding-left: 45px;
    padding-right: 45px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :hover{
      opacity: 0.7;
    }
`;

const Heading = styled.div`
    color: #465E6F;
    font-size: ${props => (props.mini == true ? 24 : 48)}px;
    text-align: center;
    margin-bottom: 20px;
    @media(max-width: 700px){
      font-size: 24px;
    }
`;

const SubHeading = styled.div`
    text-align: center;
    color: rgba(10, 27, 50, 0.5);
    font-size: ${props => (props.mini == true ? 16 : 22)}px;
    margin-bottom: 20px;
    @media(max-width: 700px){
      font-size: 16px;
    }
`;

const ErrorPlaceholder = styled.div`
    text-align: center;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
    color: red;
`;

const InputPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
   align-items: center;
   margin-bottom: 20px;
`;

const Input = styled.input`
    background: white;
    color: #465E6F;
    font-size: 24px;
    text-align: center;
    width: 560px;
    height: 70px;
    border-radius: 4px;
    border: 1px solid white;
    letter-spacing: 12px;
    :focus{
      outline: none;
      border-color: grey;
    }
`;
