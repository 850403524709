import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

import Select from 'react-select'
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";


export default function UpdateRidForm(props) {
    const {
        loading = false,
        canDelete = false,
        onSave = d => {

        },
        onDelete = () => {

        },
        allRids = []
    } = props;

    const parentRidsOptions = allRids.filter(a => (a.parentId == undefined)).map(a => ({value: a.id, label: a.name}));

    const [avatar, setAvatar] = useState(props.avatar);

    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [parentId, setParentId] = useState(props.parentId);
    const [fields, setFields] = useState(props.fields == undefined ? [] : props.fields);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Название РИДа
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value)
                }}/>
            </Field>


            <Field>
                <Label>
                    Описание РИДа
                </Label>
                <Textarea style={{minHeight: '14em'}} value={description} onChange={evt => {
                    setDescription(evt.target.value)
                }}/>
            </Field>

            <Field>
                <Label>
                    Родительский рид
                </Label>
                <Select options={parentRidsOptions}
                        placeholder={'Выберите родительский рид'}
                        value={parentRidsOptions.filter(a => (a.value == parentId))[0]}
                        onChange={a => {
                            setParentId(a.value);
                        }}/>
            </Field>

            {parentId != undefined ? null :
                <Field>
                    <Label>
                        Иконка
                    </Label>
                    <div>
                        {avatar == undefined ? null :
                            <img style={{height: 120}} src={avatar}/>
                        }
                    </div>
                    <SimpleUploadPanel onUploaded={pld => {
                        console.log('SimpleUploadPanel: onUploaded: pld = ', pld);
                        setAvatar(pld.url);
                    }}>
                        <div>
                            загрузить аватар
                        </div>
                    </SimpleUploadPanel>

                </Field>
            }


            <FieldsPlaceholder>
                {fields.map((a, i) => {
                    return (
                        <FieldItem key={a.id}>
                            <Field>
                                <Label>
                                    Название поля
                                </Label>
                                <Input value={a.name} onChange={evt => {
                                    let s = evt.target.value;
                                    let newFields = fields.map((x, j) => (i == j ? ({...x, name: s}) : x));
                                    setFields(newFields);
                                }}/>
                                <div style={{textAlign: 'right'}}>
                                    <span
                                        onClick={() => {
                                            let newFields = fields.filter(x => (x.id != a.id));
                                            setFields(newFields);
                                        }}
                                        style={{
                                            fontSize: 10,
                                            opacity: 0.5,
                                            color: 'black',
                                            fontStyle: 'italic',
                                            cursor: 'pointer'
                                        }}>удалить поле</span>
                                </div>
                            </Field>
                        </FieldItem>
                    )
                })}

                <div style={{marginTop: 10}}>
                    <AddFieldSpan onClick={() => {
                        setFields(fields.concat([{
                            id: uuid(),
                            name: '',
                            type: 'input'
                        }]))
                    }}>
                        добавить еще одно поле
                    </AddFieldSpan>
                </div>

            </FieldsPlaceholder>

            <BottomPlaceholder>

                <Spin visible={loading}/>

                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            fields: fields,
                            name: name,
                            description: description,
                            parentId: parentId,
                            avatar: avatar
                        })
                    }}>
                        Сохранить
                    </Button>
                }

            </BottomPlaceholder>


            {canDelete == false ? null :
                <div style={{marginTop: 20}}>
                    <DeleteSpan onClick={() => {
                        if (window.confirm('Вы уверены?') == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        Удалить
                    </DeleteSpan>
                </div>
            }

        </Wrapper>
    );
}

const DeleteSpan = styled.span`
    cursor: pointer;
    color: red;
    font-size: 12px;
    :hover{
      opacity: 0.6;
    }
`;

const AddFieldSpan = styled.span`
    color: black;
    opacity: 0.8;
    cursor: pointer;
    font-size: 12px;
`;

const Wrapper = styled.div`
    
`;

const FieldItem = styled.div`
    margin-bottom: 5px;
`;

const FieldsPlaceholder = styled.div`
    margin-top: 20px;
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 14px;
`;

const BottomPlaceholder = styled.div`
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid whitesmoke;
`;
