import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import * as actions from "../../../redux/actions/RidsActions";

const getSortedRids = rids => {
    return rids.sort((a, b) => {
        let s1 = `${a.name}`.toLowerCase();
        let s2 = `${b.name}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    })
}

const renderChildItems = (items, selectedId, onClick = x => {

}) => {
    return (
        <ChildrenList>
            {items.map((a, j) => {
                let isSelected = (selectedId == a.id);
                return (
                    <ChildItem key={`${a.id}_${j}`} selected={isSelected} onClick={() => {
                        onClick(a);
                    }}>
                        <ChildName>
                            {a.name}
                        </ChildName>
                    </ChildItem>
                )
            })}
        </ChildrenList>
    );
}

export default function RidTypeSelectorTool(props) {
    const {
        onSelect = rid => {
            console.log(`selected rid = `, rid);
        },
        onUnselect = () => {

        }
    } = props;

    const [selectedParentId, setSelectedParentId] = useState(undefined);
    const [selectedId, setSelectedId] = useState(props.selectedId);
    const {rids = [], getChildren, childlessParents, ridsMap} = useMappedState(useCallback(state => {
        let rids = state.rids.ridsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp));
        let getChildren = pId => getSortedRids(rids.filter(a => (a.parentId == pId)));
        let childlessParents = rids.filter(a => ((getChildren(a.id).length == 0) && (a.parentId == undefined)));

        return {
            loading: state.users.loading || state.rids.loading,
            rids: rids,
            childlessParents: childlessParents,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            getChildren: getChildren,
            ridsMap: state.rids.ridsMap
        }
    }, []));
    const dispatch = useDispatch();
    let selectedItem = (selectedId == undefined) ? undefined : rids.filter(a => (a.id == selectedId))[0];

    useEffect(() => {
        dispatch(actions.loadAllRids());
    }, []);

    useEffect(() => {
        if (selectedId != undefined) {
            let item = ridsMap.get(selectedId);
            onSelect(item);
        }
    }, [selectedId]);

    const parents = rids.filter(a => (a.parentId == undefined));

    console.log('render: rids = ', rids);

    let selectedItems = (selectedParentId == undefined) ? [] : getChildren(selectedParentId);

    return (
        <Wrapper>

            {/*<ChildlessPlaceholder>*/}
            {/*{renderChildItems(childlessParents, selectedId, x => {*/}
            {/*setSelectedId(x.id);*/}
            {/*})}*/}
            {/*</ChildlessPlaceholder>*/}

            <ParentsList>
                {parents.map((a, i) => {
                    let items = getChildren(a.id);
                    return (
                        <ParentItem key={a.id}
                                    selected={(a.id == selectedParentId)}
                                    onClick={() => {
                                        setSelectedParentId(a.id);
                                        onUnselect();
                                    }}>
                            <ParentImage src={a.avatar}/>
                            <ParentItemName>
                                {a.name}
                            </ParentItemName>
                        </ParentItem>
                    )
                })}
            </ParentsList>

            {selectedParentId == undefined ? null :
                <ChildrenList style={{marginTop: 50}}>
                    {renderChildItems(selectedItems, selectedId, x => {
                        setSelectedId(x.id);
                    })}
                </ChildrenList>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 10px;
`;

const ChildName = styled.div`
    
`;

const ChildlessPlaceholder = styled.div`
    
`;

const ParentsList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

const ParentItem = styled.div`
    margin: 10px;
    background: #FFFFFF;
    box-shadow: ${props => (props.selected ? '6px 6px 54px rgba(0, 114, 188, 0.2)' : '6px 6px 54px rgba(0, 0, 0, 0.03)')};
    border-radius: 18px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    
    width: 97px;
    
    cursor: pointer;
`;

const ParentImage = styled.img`
    width: 52px;
    height: 52px;
    margin-bottom: 10px;
`;

const ChildrenList = styled.div`
    text-align: center;
`;

const ChildItem = styled.div`
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    padding: 10px;
    margin: 10px;
    border: 1px solid lightgrey;
    padding-left: 25px;
    padding-right: 25px;
    background: ${props => (props.selected ? '#0072BC' : 'white')};
    color: ${props => (props.selected ? 'white' : 'black')};
    cursor: pointer;
    border-radius: 1000px;
    :hover{
      opacity: 0.8;
    }
`;

const ParentItemName = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
    text-align: center;
`;
