import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/PlansActions";

export default function PickPlanPanel(props) {
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const {loading, plans, isAdmin, currentUser} = useMappedState(useCallback(state => {
        return {
            loading: state.users.loading || state.plans.loading,
            plans: state.plans.plansMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllPlans());
    }, []);

    return (
        <Wrapper>
            <ul>
                {plans.map((p, i) => {
                    return (
                        <li key={i}>
                            <div>
                                {p.name}
                            </div>
                            <div onClick={() => {
                                try{
                                    window.Paddle.Checkout.open({
                                        product: p.paddle_id,
                                        email: currentUser.email,
                                        passthrough: currentUser.id
                                    });
                                }catch(exc){

                                }
                            }} >
                                select
                            </div>
                        </li>
                    )
                })}
            </ul>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
