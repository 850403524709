import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'

export default function RecoverPasswordForm(props) {
    const {
        onSubmit = () => {

        },
        onSignUpClick = () => {
        },
        onLoginClick = () => {
        }, loading = false
    } = props;
    const [email, setEmail] = useState('');

    return (
        <Wrapper>

            <TopPlaceholder>
                Восстановление пароля
            </TopPlaceholder>

            <SubTopPlaceholder>
                Пожалуйста, введите свою почту.
                <br/>
                Мы вышлем Вам ссылку на восстановление пароля
            </SubTopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <Input value={email} type={'email'} placeholder={'Ваш E-mail'} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                </Field>
                <Field>
                    <Button onClick={() => {
                        onSubmit(email)
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Восстановить'
                        }

                    </Button>
                </Field>
            </ContentPlaceholder>

            <BottomPlaceholder>
                <SignupSpan onClick={() => {
                    onLoginClick();
                }}>
                    Вход
                </SignupSpan>
                <SignupSpan onClick={() => {
                    onSignUpClick();
                }}>
                    Регистрация
                </SignupSpan>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const SignupSpan = styled.span`
    cursor: pointer;
    color: #0072BC;
    letter-spacing: -0.0642857px;
    text-decoration-line: underline;
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
    margin-left: 16px;
    :hover{
      opacity: 0.7;
    }
`;

const Wrapper = styled.div`
    //width: 100%;
    //height: 100%;
    padding: 20px;
    box-sizing: border-box;
    @media(max-width: 720px){
      width: 100vw;
    }
`;

const SubTopPlaceholder = styled.div`
    color: #202224;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.0642857px;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 25px;
`;


const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: #465E6F;
    text-align: center;
`;

const ContentPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;

const LogoPlaceholder = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
`;

const Logo = styled.img`
    display: inline-block;
    width: 80%;
`;
