import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import AdminAllUsersPanel from "../users/panels/AdminAllUsersPanel";

export default function AdminUsersApp() {

    return (
        <SubtitlesTemplate active={'users'}>

            <AdminAllUsersPanel/>

        </SubtitlesTemplate>
    );
}

const Wrapper = styled.div`
    
`;
