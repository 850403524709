import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';


import LandingApp from './LandingApp'
import DevApp from './DevApp'
import AdminRidsApp from './AdminRidsApp'
import AdminFaqApp from './AdminFaqApp'
import AdminSubscriptionsApp from './AdminSubscriptionsApp'
import AdminIndexApp from './AdminIndexApp'
import AdminUsersApp from './AdminUsersApp'
import UserIndexApp from "./UserIndexApp";
import UserSettingsApp from "./UserSettingsApp";
import UserBillingApp from "./UserBillingApp";
import KnowledgeApp from "./KnowledgeApp";

import RecoverPasswordApp from "./RecoverPasswordApp";

import MobileHelpApp from './MobileHelpApp'

import FullPagePreloader from '../preloader/FullPagePreloader'

import ls from 'local-storage'

import * as usersActions from '../../redux/actions/UsersActions'
import * as projectsActions from '../../redux/actions/ProjectsActions'
import * as ridsActions from '../../redux/actions/RidsActions'
import * as plansActions from '../../redux/actions/PlansActions'
import LoginApp from "./LoginApp";
import UserPricingApp from "./UserPricingApp";
import UserHelpApp from "./UserHelpApp";
import UserProfileApp from "./UserProfileApp";
import ProjectApp from "./ProjectApp";
import ProjectViewApp from "./ProjectViewApp";
import ProjectCertApp from "./ProjectCertApp";
import VerificationApp from "./VerificationApp";

const adminRoute = (
    <Switch>

        <Route exact path='/verification' component={VerificationApp}/>

        <Route exact path='/faq' component={AdminFaqApp}/>
        <Route exact path='/rids' component={AdminRidsApp}/>

        <Route exact path='/subscriptions' component={AdminSubscriptionsApp}/>
        <Route exact path='/users' component={AdminUsersApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>

        <Route exact path='/p/:id' component={ProjectViewApp}/>
        <Route exact path='/cert/:id' component={ProjectCertApp}/>

        <Route exact path='/settings' component={UserSettingsApp}/>
        <Route exact path='/knowledge' component={KnowledgeApp}/>

        <Route exact path='/profile' component={UserProfileApp}/>

        <Route component={AdminIndexApp}/>

    </Switch>
);


const userRoute = (
    <Switch>

        <Route exact path='/verification' component={VerificationApp}/>
        <Route exact path='/knowledge' component={KnowledgeApp}/>

        <Route exact path='/help' component={UserHelpApp}/>
        <Route exact path='/profile' component={UserProfileApp}/>
        <Route exact path='/pricing' component={UserPricingApp}/>
        <Route exact path='/billing' component={UserBillingApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/settings' component={UserSettingsApp}/>

        <Route exact path='/p/:id' component={ProjectViewApp}/>

        <Route exact path='/cert/:id' component={ProjectCertApp}/>

        <Route component={UserIndexApp}/>
    </Switch>
);

const doctorRoute = (
    <Switch>
        <Route component={UserIndexApp}/>
    </Switch>
);

const guestRoute = (
    <Switch>

        <Route exact path='/verification' component={VerificationApp}/>

        <Route exact path='/login' component={LoginApp}/>
        <Route exact path='/knowledge' component={KnowledgeApp}/>
        <Route exact path='/dev' component={LoginApp}/>
        <Route exact path='/support' component={LoginApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path='/mobile_help' component={MobileHelpApp}/>
        <Route exact path='/p/:id' component={ProjectViewApp}/>
        <Route exact path='/cert/:id' component={ProjectCertApp}/>
        <Route component={LandingApp}/>
    </Switch>
);


function RouterApp(props) {
    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser} = useMappedState(mapState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then(pld => {
            if (pld.user != undefined) {
                console.log('==>>> pld = ', pld);
                dispatch(projectsActions.loadUserProjects(pld.user.id));
                dispatch(ridsActions.loadAllRids());
            }
        });
    }, []);

    if (initialized == false) {
        return (
            <FullPagePreloader visible={true}/>
        )
    }

    let route = guestRoute;
    let isGuest = (currentUser == undefined || (ls('force_landing') == true));
    let isAdmin = (isGuest == false && currentUser.userRole == 'admin');

    if (isGuest == false) {
        route = isAdmin == true ? adminRoute : userRoute;
    }

    return (
        <HashRouter>
            <Wrapper>
                {route}
            </Wrapper>
        </HashRouter>
    );
}

const Wrapper = styled.div`
    //font-family: "Avenir Next";
    font-family: 'Roboto', sans-serif;
`;

export default (RouterApp)
