import moment from 'moment-timezone'
import {DEFAULT_TIMEZONE} from "../constants/config";
import axios from 'axios'

export const RU_MONTHS_ARRAY = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

const CommonHelper = {

    linkTo(patientUrl, newTab = false) {
        if (newTab == true) {
            let win = window.open(patientUrl, '_blank');
            win.focus();
        }
        let url = window.location.origin + window.location.pathname + '#' + patientUrl;
        window.location.href = url;
    },

    getRuMonthByTimestamp(t) {
        let m = +moment(t).tz(DEFAULT_TIMEZONE).format('M') - 1;
        return RU_MONTHS_ARRAY[+m];
    },

    getEnMonthByTimestamp(t) {
        return moment(t).tz(DEFAULT_TIMEZONE).format('MMMM');
    },

    getCurrentUserRole(state) {
        if (state == undefined) {
            return false;
        }
        let {currentUserId, usersMap} = state.users;
        let currentUser = usersMap.get(currentUserId);
        if (currentUser == undefined) {
            return undefined;
        }
        return currentUser.userRole;
    },

    getUserName(user) {
        if (user == undefined) {
            return 'Guest';
        }
        let {firstName, lastName, name, email} = user;
        if (firstName == undefined && lastName == undefined) {
            return email;
        }
        return `${firstName} ${lastName} (${email})`;
    },

    downloadFile(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },

    async getFile(url, onProgress) {
        let d = (await axios.get(url, {
            responseType: 'arraybuffer',
            onDownloadProgress: progressEvent => {
                let {loaded, total} = progressEvent;
                let progress = 100.0 * (+loaded) / (+total);
                window.cachingProgress = progress;
                onProgress(progress);
            }
        })).data;
        const blob = new Blob([d]);
        return blob;
    },

    async getCachedUrl(url, onCachingProgress = p => {

    }) {
        let registry = window.cachedRegistry || {};
        if (registry[url] != undefined) {
            return registry[url];
        }
        let d = await this.getFile(url, onCachingProgress);
        // let objectUrl = window.URL.createObjectURL(new Blob(d, {type: "video/mp4"}));
        let objectUrl = window.URL.createObjectURL(d);
        registry[url] = objectUrl;
        window.cachedRegistry = registry;
        return objectUrl;
    },

    getGradColor(ratio = 0.5) {
        let color1 = '27E634';
        let color2 = 'F73434';
        let hex = function (x) {
            x = x.toString(16);
            return (x.length == 1) ? '0' + x : x;
        };
        let r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
        let g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
        let b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio));
        return `#${hex(r)}${hex(g)}${hex(b)}`
    },

    getElementCssProp(id, propName, defaultValue) {
        try {
            let elem = document.getElementById(id);
            let theCSSprop = window.getComputedStyle(elem, null).getPropertyValue(propName);
            if (theCSSprop == undefined) {
                return defaultValue;
            }
            return theCSSprop;
        } catch (e) {
            return defaultValue;
        }
    },

    //proportion: w / h
    getContainerDimensions(containerSize = {}, proportion = 1.0) {
        console.log('getContainerDimensions: containerSize, proportion = ', containerSize, proportion);
        let {width, height} = containerSize;
        let containerRatio = (width == undefined) ? 1 : (1.0 * width / height);
        let videoWidth = width, videoHeight = height;
        if (proportion > containerRatio) { // wider
            videoWidth = width;
            videoHeight = 1.0 * width / proportion
        } else { // narrower
            videoHeight = height;
            videoWidth = 1.0 * height * proportion;
        }
        console.log('returning videoWidth, videoHeight  = ', videoWidth, videoHeight);
        return {
            width: videoWidth,
            height: videoHeight
        }
    },

    getIconByUrl(url) {
        let s = `${url}`.toLowerCase();
        if (s.indexOf('.doc') > -1 || s.indexOf('.docx') > -1) {
            return require('../assets/images/icons/doc_file.svg')
        }
        if (s.indexOf('.xls') > -1 || s.indexOf('.xlsx') > -1) {
            return require('../assets/images/icons/xls_file.svg')
        }
        if (s.indexOf('.zip') > -1) {
            return require('../assets/images/icons/zip_file.svg')
        }
        if (s.indexOf('.pdf') > -1) {
            return require('../assets/images/icons/pdf_file.svg')
        }
        if (s.indexOf('.png') > -1) {
            return require('../assets/images/icons/png_file.svg')
        }
        if (s.indexOf('.jpg') > -1 || s.indexOf('.jpeg') > -1) {
            return require('../assets/images/icons/jpg_file.svg')
        }
        if (s.indexOf('.mp4') > -1 || s.indexOf('.avi') > -1 || s.indexOf('.mpg') > -1 || s.indexOf('.vob') > -1) {
            return require('../assets/images/icons/video_file.svg');
        }
        return require('../assets/images/icons/general_file.svg');
    },

    isImageUrl(url) {
        let s = `${url}`.toLowerCase();
        return ['.jpg', '.jpeg', '.png', '.bmp', '.gif'].reduce((f, a) => (s.indexOf(a) > -1 || f), false);
    }


};

export default CommonHelper;
