import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'

export default function FieldsInputFormTool(props) {
    const {
        loading = false,
        fields = [],
        data = {},
        onSave = d => {

        },
        onChange = d => {

        },
        canSave = false
    } = props;

    const [formData, setFormData] = useState(data == undefined ? {} : data);

    return (
        <Wrapper>

            <FieldsPlaceholder>

                {fields.map((a, i) => {
                    let val = (formData[a.id] == undefined || formData[a.id].value == undefined) ? '' : formData[a.id].value;
                    return (
                        <FieldItem key={`${a.id}_${i}`}>
                            <FieldLabel>
                                {a.name}
                            </FieldLabel>
                            <InputPlaceholder>
                                <Input value={val} onChange={evt => {
                                    let s = evt.target.value;
                                    let dd = {
                                        id: a.id,
                                        name: a.name,
                                        value: s
                                    };
                                    let newD = {
                                        ...formData,
                                        [a.id]: dd
                                    };
                                    setFormData(newD);
                                    onChange(newD);
                                }}/>
                            </InputPlaceholder>
                        </FieldItem>
                    )
                })}

            </FieldsPlaceholder>

            {canSave == false ? null :
                <BottomSaveButtonPlaceholder>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <Button onClick={() => {
                            onSave(formData);
                        }}>
                            Save
                        </Button>
                    }

                </BottomSaveButtonPlaceholder>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`
    
`;

const BottomSaveButtonPlaceholder = styled.div`
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid lightgrey;
`;

const FieldLabel = styled.div`
    font-size: 14px;
    font-weight: bold;  
    color: #202224;
    opacity: 0.6;
    margin-bottom: 10px;
`;

const FieldsPlaceholder = styled.div`
    
`;

const FieldItem = styled.div`
    margin-bottom: 15px;
`;

const InputPlaceholder = styled.div`
    
`;
