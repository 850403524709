import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import ViewFaqItemsTool from "../tools/ViewFaqItemsTool";

import {Code} from 'react-content-loader'

function SelfLoadingFaqPanel() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/dictionary/faq`).then(d => d.data.result.value.items).then(arr => {
            setItems(arr);
        });
    }, []);

    if (items.length == 0){
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <ViewFaqItemsTool items={items}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

export default SelfLoadingFaqPanel
