import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ProjectsAPI = {

    async updateProjectSubtitles(id, subtitles = []) {
        let pld = (await axios.post(`${API_ENDPOINT}/project/update_subtitles`, {id: id, subtitles: subtitles})).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getProjectByTimestampOrHash(s) {
        if (window.isNaN(s) == false) {
            let pld1 = (await axios.get(`${API_ENDPOINT}/project/ts/${s}`)).data;
            if (pld1.result != undefined){
                return pld1.result;
            }
        }
        let pld2 = (await axios.get(`${API_ENDPOINT}/project/hash/${s}`)).data;
        return pld2.result;
    },

    async getProject(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getProjectAwsSubtitles(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return (pld.result.awsSubtitles == undefined ? [] : pld.result.awsSubtitles);
    },

    async getAllProjects() {
        let pld = (await axios.get(`${API_ENDPOINT}/projects/all`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getUserProjects(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/projects`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


    async createProject(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/project`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateProject(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/project`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async deleteProject(id) {
        let pld = (await axios.post(`${API_ENDPOINT}/project/delete`, {id: id})).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


}

export default ProjectsAPI;
