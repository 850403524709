import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import './style.css'

const expandImg = require('../../../assets/images/arrow_down.svg');

export default function ViewFaqItemsTool(props) {
    const {items = []} = props;
    const [expandedSet, setExpandedSet] = useState(Set());

    return (
        <Wrapper  >

            {items.map((item, k) => {
                const expanded = expandedSet.has(k);
                let {question, answer} = item;
                return (
                    <Item key={k} onClick={() => {
                        if (expanded == true) {
                            setExpandedSet(expandedSet.delete(k))
                        } else {
                            setExpandedSet(expandedSet.add(k))
                        }
                    }}>

                        <ItemTop>
                            <Question>
                                {question}
                            </Question>
                            <ExpImg expanded={expanded} src={expandImg}/>
                        </ItemTop>


                        {expanded == false ? null :
                            <Answer className={'super_faq'} >
                                <div
                                    dangerouslySetInnerHTML={{__html: (answer == undefined ? '' : answer).replace(/\n/g, '<br/>')}}>
                                </div>
                            </Answer>
                        }

                    </Item>
                )
            })}

        </Wrapper>
    );
}


const Wrapper = styled.div`
    text-align: left;
    margin: 0 auto;
`;

const Item = styled.div`
    margin-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
    background: white;
    padding: 10px;
    border-radius: 3px;
`;

const ItemTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Question = styled.div`
    font-size: 17px;
    line-height: 20px;
    padding-right: 10px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const LoadingWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #2D374B;
    font-size: 17px;
    line-height: 20px;
`;

const LoaderImage = styled.img`
    //width: 70vw;
    display: inline-block;
`;

// const Expander = styled.div`
//     width: 40px;
//     display: flex;
//     align-items: center;
// `;

const Answer = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    line-height: 20px;
    color: #2D374B;
`;

const ExpImg = styled.img`
    width: 13px;
    transition-duration: 0.2s;
    transform: rotate(${props => (props.expanded == true ? 180 : 360)}deg);
    :hover{
      opacity: 1;
    }
`;

const replace_content = (content) => {
    let exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    let element_content = content.replace(exp_match, "<a href='$1'>$1</a>");
    let new_exp_match = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    let new_content = element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2">$2</a>');
    return new_content;
}
