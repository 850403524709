import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const RidsAPI = {

    async getRid(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/rids/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result.rid;
    },

    async getAllRids() {
        let pld = (await axios.get(`${API_ENDPOINT}/rids`)).data;
        return pld.result;
    },

    async createRid(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/rids`, data)).data;
        return pld.result;
    },

    async updateRid(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/rids`, data)).data;
        return pld.result;
    },

    async deleteRid(id) {
        await axios.delete(`${API_ENDPOINT}/rids`, {data: {id: id}})
    },


}

export default RidsAPI;
