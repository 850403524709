import {combineReducers} from 'redux';

import UsersReducer from './UsersReducer.js';
import PlansReducer from './PlansReducer.js';
import ProjectsReducer from './ProjectsReducer.js';
import RidsReducer from './RidsReducer.js';

export const reducer = combineReducers({
    rids: RidsReducer,
    projects: ProjectsReducer,
    users: UsersReducer,
    plans: PlansReducer
});
