import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import ProjectViewPanel from "../projects/panels/ProjectViewPanel";

export default function ProjectApp(props) {
    let {id} = props.match.params;

    return (
        <SubtitlesTemplate active={`p/${id}`}>

            <ProjectViewPanel id={id} />

        </SubtitlesTemplate>
    );
}

const Wrapper = styled.div`
    
`;
